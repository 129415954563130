
import { useMutation } from "@tanstack/react-query"
import { GeneralMutationParams } from "utils/types"
import { GeneralChoiceQuestionRequest, GeneralChoiceQuestionResponse } from "utils/api/cms/types"
import { createChoiceQuestion } from "../../functions"

const useMutationCreateChoiceQuestion = (
    options?: GeneralMutationParams<
        GeneralChoiceQuestionResponse,
        GeneralChoiceQuestionRequest
    >
) => {
    return useMutation({
        mutationFn: (payload) => createChoiceQuestion(payload),
        ...options,
    })
}

export default useMutationCreateChoiceQuestion