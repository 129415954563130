import { requestCMS } from "utils/axiosInstance"
import {  getChoiceQuestionResponse } from "../../types"

const getChoiceQuestion = async (question_id: string) => {
    const res = await requestCMS.get<getChoiceQuestionResponse>(
        `/employee/course/choice?limit=5&question_id=${question_id}`
    )
    return res
}

export default getChoiceQuestion;
