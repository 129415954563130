import React, { useRef } from 'react';
import sertifikat from "assets/SertifikatDesain/sertifikat.png";
import Section from 'components/ui/section/Section';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from "moment"
import Typography from 'components/ui/typography';
import Button from 'components/ui/button/Button';
import { useParams } from 'react-router-dom';
import { useGetCourseDetail, useGetWorkshopDetail } from 'utils/api/public/queries/hooks';
import { useUserInfoStore } from 'utils/store/user-info';

const CourseSertifikat = () => {
  const { slug } = useParams()
  const { data } = useGetCourseDetail({ slug: slug ?? "" })
  const dataCourse = data?.data

  const sertifikatRef = useRef<HTMLDivElement>(null);

  const { userInfo } = useUserInfoStore()


  const downloadCertificate = () => {
    if (!sertifikatRef.current) return;

    html2canvas(sertifikatRef.current, { scale: 2, logging: false, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1);
      const pdf = new jsPDF('landscape');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('sertifikatBeeplus.pdf');
    });
  };

  return (
    <Section
      withContainer
      className={`flex flex-col items-center justify-center gap-8 py-8`}
    >
      <div ref={sertifikatRef} className="relative">
        <img className='' src={sertifikat} alt="" />
        <div className='absolute mt-2 top-[33%] w-full flex flex-col justify-center items-center'>
          <Typography variant='heading4' className='mb-1'  >
            {userInfo.first_name} {userInfo.middle_name}{" "}
            {userInfo.last_name}
          </Typography>
          <Typography variant='heading4' className='mt-12 px-16 text-center'>
            {dataCourse?.title}
          </Typography>
          <Typography variant='subheading4' className='mt-6 text-gray-400'>
            {`${moment(dataCourse?.created_at).format("DD MMM YYYY")}`}
          </Typography>
        </div>
      </div>
      <Button onClick={downloadCertificate}>Download PDF</Button>
    </Section>
  );
};

export default CourseSertifikat;
