import { useQuery } from "@tanstack/react-query"

import { getCourseQuestion } from "../functions"
import { GetCourseQuestionParams, UserQueryKey } from "../../types"

const useGetCourseQuestion = (params?: GetCourseQuestionParams) => {
  return useQuery({
    queryKey: [UserQueryKey.CourseQuestion, params?.exam_id ?? ""],
    queryFn: () => getCourseQuestion(params?.exam_id ?? ""),
    ...params?.options,
  })
}

export default useGetCourseQuestion
