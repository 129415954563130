import { type SubmitHandler, useForm } from "react-hook-form"
import clsx from "clsx"
import TextInput from "components/ui/form/TextInput"
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import TextAreaInput from "components/ui/form/TextAreaInput"
import { SertifikasiPayload } from "utils/api/user/types"
import { GeneralSertifikasiRequest } from "utils/api/public/types"
import { useGetSertifikasiSlug, useMutationSendSertifikasi } from "utils/api/public/queries/hooks"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"


interface SertifikasiForm {
    sertifikat_id: number
    name: string
    email: string
    phone: string
    profesi: string
    program: string
}

const SertifikasiForm = (params) => {
    const navigate = useNavigate()
    const {
        formState: { errors },
        watch,
        register,
        // reset,
        // handleSubmit,
    } = useForm<SertifikasiPayload>()

    const { slug } = useParams()
    const { data: dataDetail } = useGetSertifikasiSlug({
        slug: slug ?? "",
    })
    
    useEffect(() => {
        if (dataDetail?.data) {
            setProgram(dataDetail.data.name ?? '');
        }
    }, [dataDetail]);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [profesi, setProfesi] = useState('');
    const [program, setProgram] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const phoneNumber = '6282277910239';
        const whatsappMessage = `Halo MinBee \nSaya ${name}\n\nEmail: ${email}\nNo. HP: ${phone}\nProfesi: ${profesi}\n\ningin bertanya tentang ${program}`;
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <>
            <Section space="top-bottom">
                <div
                    className={clsx(
                        "flex flex-col items-center justify-center",
                        "mx-8 md:mx-auto",
                        "max-w-5xl",
                        "rounded",
                        "space-y-4",
                        "md:py-16 md:px-[200px] md:shadow"
                    )}
                >
                    <Typography
                        id="form-redirect-wa"
                        as="h4"
                        variant="heading4"
                        className="mb-10"
                    >
                        Daftar Sekarang
                    </Typography>
                    <form
                        className="w-full space-y-5"
                        onSubmit={handleSubmit}>
                        <TextInput
                            label="Nama Lengkap"
                            name="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            isRequired
                        />
                        <TextInput
                            label="Email Anda"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isRequired
                        />
                        <TextInput
                            label="Nomer WA Anda"
                            name="phone"
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            isRequired
                        />
                        <TextAreaInput
                            cols={6}
                            rows={6}
                            inputClass={clsx(
                                " mt-2 w-full resize-none",
                                errors.name && "cms-base-error-input",
                                !errors.name && "cms-base-input"
                            )}
                            labelClass="font-semibold"
                            label="Profesi dan Asal Instansi"
                            isRequired
                            name="profesi"
                            value={profesi}
                            onChange={(e) => setProfesi(e.target.value)}
                        />
                        <TextAreaInput
                            cols={6}
                            rows={6}
                            inputClass={clsx(
                                " mt-2 w-full resize-none",
                                errors.name && "cms-base-error-input",
                                !errors.name && "cms-base-input"
                            )}
                            labelClass="font-semibold"
                            label="Program Sertifikasi yang ingin Diikuti?"
                            name="program"
                            value={program}
                            onChange={(e) => setProgram(e.target.value)}
                        />
                        <Button
                            className="!mt-8 text-white text-lg bg-green-400 border-green-400 h-15 "
                            type="submit"
                            size="lg"
                            fullWidth
                        >
                            Daftar via whatsapp
                        </Button>
                    </form>
                </div>
            </Section>

        </>
    )
}

export default SertifikasiForm
