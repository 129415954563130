import { requestCMS } from "utils/axiosInstance"
import { DeleteCourseQuestionDetailResponse } from "utils/api/cms/types"

const deleteQuestion = async (questionID: string) => {
  const res = await requestCMS.delete<DeleteCourseQuestionDetailResponse>(
    `/employee/course/question/${questionID}`
  )
  return res
}

export default deleteQuestion
