import { requestCMS } from "utils/axiosInstance"
import { GeneralApiResponse, QueryParams } from "utils/types"
import { CourseCategoryQuestion } from "../../types"

const getCourseCategoryQuestion = async (qParams?: QueryParams) => {
    const res = await requestCMS.get<GeneralApiResponse<CourseCategoryQuestion[]>>(
        "/employee/course/exam",
        qParams
    )
    return res
}

export default getCourseCategoryQuestion
