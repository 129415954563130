import { useForm } from "react-hook-form"
import clsx from "clsx"
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid"
import {
  useDebounce,
  useMediaQuery,
  useIntersectionObserver,
} from "usehooks-ts"

import SideFilter from "components/ui/filter/SideFilter"
import CourseCard from "../../components/CourseCard"
import { useGetCourseCategories } from "utils/api/public/queries/hooks"
import { useInfiniteGetCourses } from "utils/api/public/queries/hooks"
import { Fragment, useEffect, useRef, useState } from "react"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import CardVerticalSkeleton from "components/public/components/CardVerticalSkeleton"
import { BsFilter } from "react-icons/bs"
import DrawerRight from "components/ui/drawer/DrawerRight"

const ListCourse = () => {
  const desktop = useMediaQuery("(min-width: 1024px)")
  const ref = useRef<HTMLParagraphElement | null>(null)

  const entry = useIntersectionObserver(ref, {})

  const { register, watch } = useForm()

  const searchValue = useDebounce<string>(watch("search"), 500)

  const { data: dataCategories } = useGetCourseCategories({
    options: {
      staleTime: 5 * 60 * 1000,
    },
  })

  const {
    data: dataCourses,
    isLoading: isLoadingCourses,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteGetCourses({
    qParams: {
      category_id: Number(watch("category")) || undefined,
      keyword: searchValue || undefined,
      limit: 9,
    },
  })

  useEffect(() => {
    if (!entry?.isIntersecting) return
    fetchNextPage()
  }, [entry])

  const categoryItems = dataCategories?.data.map((category) => ({
    name: category.name,
    value: category.id,
  }))

  return (
    <div className="flex flex-col lg:flex-row gap-4">
      {desktop ? (
        <div className="sticky top-24 h-fit">
          <div className="flex relative mb-4 mr-10">
            <input
              className={clsx(
                "h-8 w-full",
                "pl-7",
                "border-b border-b-typo-gray",
                "focus:outline-none focus:border-b-primary focus:border-b-2 peer"
              )}
              placeholder="Cari kelas"
              {...register("search")}
            />
            <MagnifyingGlassIcon
              width={20}
              height={20}
              className="absolute bottom-2 left-0 text-typo-gray peer-focus:text-primary"
            />
          </div>
          <SideFilter
            sections={[
              {
                category: "Kategori",
                register: register("category"),
                items: [{ name: "Semua", value: 0 }, ...(categoryItems ?? [])],
              },
            ]}
          />
        </div>
      ) : (
        <div className="relative">
          <DrawerRight
            height="100%"
            className="w-2/3 "
            renderButton={({ handleDrawer }) => (
              <Button
                className="w-full flex justify-center items-center gap-2 mb-12"
                size="lg"
                variant="outlined"
                color="primary"
                onClick={() => handleDrawer(true)}
              >
                <BsFilter className="w-8 h-8 font-bold" />
                Filter
              </Button>
            )}
          >
            {({ handleDrawer }) => (
              <div className="drawer-content w-full">
                <SideFilter
                  handleDrawer={() => handleDrawer(false)}
                  sections={[
                    {
                      category: "Kategori",
                      register: register("category"),
                      items: [{ name: "Semua", value: 0 }, ...(categoryItems ?? [])],
                    },
                  ]}
                />
              </div>
            )}
          </DrawerRight>
        </div>

      )}
      <div
        className={clsx(
          "w-full",
          "flex flex-wrap justify-center gap-4 lg:justify-start"
        )}
      >
        {isLoadingCourses
          ? [...Array(6)].map((_, index) => (
            <CardVerticalSkeleton className="max-sm:w-full" key={index} />
          ))
          : dataCourses?.pages.map((page, index) => (
            <Fragment key={index}>
              {page.data.map((course) => (
                <CourseCard
                  key={course.id}
                  to={`/courses/${course.slug}`}
                  imgSrc={course.thumbnail}
                  title={course.title}
                  currency={course.price.currency_text}
                  currentPrice={course.price.current_price_text}
                  masterPrice={course.price.master_price_text}
                  className="max-sm:w-full"
                  lecturer={course.lecturer}
                  totalVideo={(course.videos_count ?? 0) + " Video"}
                />
              ))}
            </Fragment>
          ))}
        {hasNextPage && (
          <div
            ref={ref}
            className="col-span-3"
          >
            {isFetchingNextPage ? (
              <Typography className="text-primary">Muat Lebih...</Typography>
            ) : (
              <Button
                variant="outlined"
                onClick={() => fetchNextPage()}
              >
                Muat Lebih
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ListCourse
