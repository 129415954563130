import { useQuery } from "@tanstack/react-query"

import { getCourseSectionQuiz } from "../functions"
import { GetCourseSectionQuizParams, UserQueryKey } from "../../types"

const useGetCourseSectionQuiz = (params?: GetCourseSectionQuizParams) => {
  return useQuery({
    queryKey: [UserQueryKey.CourseSectionQuiz, params?.course_id ?? ""],
    queryFn: () => getCourseSectionQuiz(params?.course_id ?? ""),
    ...params?.options,
  })
}

export default useGetCourseSectionQuiz
