import { CSSProperties, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import clsx from "clsx"

import clsxm from "utils/clsxm"

interface DrawerState {
  open: boolean
  handleDrawer: (open: boolean) => void
}

interface DrawerProps {
  title?: string
  height?: string
  outsideOnClose?: boolean
  children: (state: DrawerState) => React.ReactNode
  renderButton: (state: DrawerState) => React.ReactNode
  className?: string
}

const DrawerRight = ({
  title,
  height,
  children,
  outsideOnClose = true,
  renderButton,
  className,
}: DrawerProps) => {
  const element = document.getElementById("portal")
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflow = "auto"
      return
    }

    document.body.style.overflow = "hidden"
  }, [isOpen])

  const handleDrawer = (open: boolean) => setIsOpen(open)

  if (!element) return <></>

  return (
    <>
      {/* Component handler */}
      {renderButton({ open: isOpen, handleDrawer })}

      {/* Drawer component */}
      {createPortal(
        <>
          <div
            style={
              {
                "--height": height,
              } as CSSProperties
            }
            className={clsxm(
              "fixed bottom-0 left-1/3 z-40",
              "h-[75%] w-full",
              "p-6",
              "rounded-l-3xl",
              "transition-transform",
              "bg-white shadow-lg",
              [!isOpen && "translate-x-full"],
              [!!height && "h-[--height]"],
              className,
            )}
            aria-labelledby="drawer-label"
          >
        
            <div>{children({ open: isOpen, handleDrawer })}</div>
          </div>

          {/* Overlays */}
          {isOpen && (
            <div
              {...(outsideOnClose && { onClick: () => handleDrawer(false) })}
              className={clsx(
                "fixed top-0 left-0 z-30",
                "w-screen h-screen overflow-hidden",
                "bg-black opacity-80"
              )}
            />
          )}
        </>,
        element
      )}
    </>
  )
}

export default DrawerRight
