import { Fragment, useEffect, useRef } from "react"
import {
  useDebounce,
  useIntersectionObserver,
  useMediaQuery,
} from "usehooks-ts"
import clsx from "clsx"
import { useForm } from "react-hook-form"

import { ReactComponent as Nowebinar } from "assets/svg/NoWebinar.svg"
import WorkshopCard from "components/public/components/WorkshopCard"
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid"
import SideFilter from "components/ui/filter/SideFilter"
import {
  useGetWorkshopCategories,
  useInfiniteGetWorkshops,
} from "utils/api/public/queries/hooks"
import Typography from "components/ui/typography"
import CardVerticalSkeleton from "components/public/components/CardVerticalSkeleton"
import Button from "components/ui/button/Button"
import { BsFilter } from "react-icons/bs"
import DrawerRight from "components/ui/drawer/DrawerRight"

const ListWorkshop = () => {
  const desktop = useMediaQuery("(min-width: 1024px)")
  const ref = useRef<HTMLParagraphElement | null>(null)

  const entry = useIntersectionObserver(ref, {})

  const { register, watch } = useForm()

  const searchValue = useDebounce<string>(watch("search"), 500)

  const { data: dataCategories } = useGetWorkshopCategories({
    options: {
      staleTime: 5 * 60 * 1000,
    },
  })

  const {
    data: dataWorkshops,
    isLoading: isLoadingWorkshops,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteGetWorkshops({
    qParams: {
      category_id: Number(watch("category")) || undefined,
      keyword: searchValue || undefined,
      limit: 10,
    },
  })

  useEffect(() => {
    if (entry?.isIntersecting) {
      fetchNextPage()
    }
  }, [entry])

  const categoryItems = dataCategories?.data.map((category) => ({
    name: category.name,
    value: category.id,
  }))

  return (
    <div className="flex flex-col lg:flex-row gap-4">
      {desktop ? (
        <div className="sticky top-24 h-fit">
          <div className="flex relative mb-4 mr-10">
            <input
              className={clsx(
                "h-8 w-full",
                "pl-7",
                "border-b border-b-typo-gray",
                "focus:outline-none focus:border-b-primary focus:border-b-2 peer"
              )}
              placeholder="Cari workshop"
              {...register("search")}
            />
            <MagnifyingGlassIcon
              width={20}
              height={20}
              className="absolute bottom-2 left-0 text-typo-gray peer-focus:text-primary"
            />
          </div>
          <SideFilter
            sections={[
              {
                category: "Kategori",
                register: register("category"),
                items: [{ name: "Semua", value: 0 }, ...(categoryItems ?? [])],
              },
            ]}
          />
        </div>
      ) : (
        <div className="relative">
          <DrawerRight
            height="100%"
            className="w-2/3 "
            renderButton={({ handleDrawer }) => (
              <Button
                className="w-full flex justify-center items-center gap-2 mb-12"
                size="lg"
                variant="outlined"
                color="primary"
                onClick={() => handleDrawer(true)}
              >
                <BsFilter className="w-8 h-8 font-bold" />
                Filter
              </Button>
            )}
          >
            {({ handleDrawer }) => (
              <div className="drawer-content w-full">
                <SideFilter
                  handleDrawer={() => handleDrawer(false)}
                  sections={[
                    {
                      category: "Kategori",
                      register: register("category"),
                      items: [{ name: "Semua", value: 0 }, ...(categoryItems ?? [])],
                    },
                  ]}
                />
              </div>
            )}
          </DrawerRight>
        </div>
      )}
      <div
        className={clsx(
          "w-full",
          "flex flex-wrap gap-4 justify-center lg:justify-start"
        )}
      >
        {isLoadingWorkshops
          ? [...Array(4)].map((_, index) => (
            <CardVerticalSkeleton className="max-sm:w-full" key={index} />
          ))
          : dataWorkshops?.pages.map((page, index) => (
            <Fragment key={index}>
              {page.data.length > 0 ? ( // Cek apakah page.data tidak kosong
                page.data.map((workshop) => {
                  return (
                    <WorkshopCard
                      key={workshop.id}
                      title={workshop.title}
                      currency={workshop.price.currency_text}
                      currentPrice={workshop.price.current_price_text}
                      masterPrice={workshop.price.master_price_text}
                      quota={workshop.quota}
                      startDate={workshop.start_date}
                      endDate={workshop.end_date}
                      slug={workshop.slug}
                      className="max-sm:w-full"
                      imgSrc={workshop.thumbnail}
                      speakersData={workshop.extras}
                    />
                  )
                })
              ) : (
                <div className="w-full grid justify-items-center items-center">
                  <Nowebinar />
                  <Typography
                    variant="subheading4"
                    as="h1"
                    className="text-tertiary w-full text-center items-center"
                  >
                    Belum ada workshop di waktu terdekat.
                  </Typography>{" "}
                </div>
              )}
            </Fragment>
          ))}
        {hasNextPage && (
          <div
            ref={ref}
            className="col-span-3"
          >
            {isFetchingNextPage ? (
              <Typography className="text-primary">Muat Lebih...</Typography>
            ) : (
              <Button
                variant="outlined"
                onClick={() => fetchNextPage()}
              >
                Muat Lebih
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ListWorkshop
