import { requestCMS } from "utils/axiosInstance"
import { QueryParams } from "utils/types"
import type { GeneralCourseResponse } from "../../types"

const getCourseAll = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<GeneralCourseResponse>(
    "/employee/course/all",
    qParams
  )
  return res
}

export default getCourseAll
