import { GeneralChoiceQuestionRequest, GeneralChoiceQuestionResponse } from "utils/api/cms/types"
import { requestCMS } from "utils/axiosInstance"

const createChoiceQuestion = async (payload: GeneralChoiceQuestionRequest) => {
    const res = await requestCMS.post<GeneralChoiceQuestionResponse>(
        "/employee/course/choice",
        payload
    )
    return res
}

export default createChoiceQuestion
