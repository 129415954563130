import { useMutation } from "@tanstack/react-query"

import {  DeleteChoiceQuestionResponse } from "../../types"
import { deleteChoiceQuestion } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationDeleteChoiceQuestion = (
    options?: GeneralMutationParams<DeleteChoiceQuestionResponse, string>
) => {
    return useMutation({
        mutationFn: (choiceID) => deleteChoiceQuestion(choiceID),
        ...options,
    })
}

export default useMutationDeleteChoiceQuestion
