import { useEffect, useRef, useState } from "react"
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid"
import ReactPlayer from "react-player"
import { Dropdown } from "flowbite-react"
import clsx from "clsx"
import { BsGear } from "react-icons/bs"
import clsxm from "utils/clsxm"

const RESOLUTION_OPTIONS = ["360p", "480p", "720p", "1080p"]

interface VideoPlayerProps {
  url: string
  token?: string
}

const VideoPlayer = ({ url, token }: VideoPlayerProps) => {
  // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const isSafari = navigator.vendor.match(/apple/i);
  const player = useRef<ReactPlayer>(null)
  const [playing, setPlaying] = useState(false)
  const [qualityLevel, setQualityLevel] = useState(
    parseInt(localStorage.getItem("video_quality") ?? "-1")
  )

  const handleSelectQualityLevel = (index: number) => {
    const internalPlayer = player.current?.getInternalPlayer("hls")
    if (internalPlayer) {
      internalPlayer.currentLevel = index
      setQualityLevel(index)
      localStorage.setItem("video_quality", index.toString())
    }
  }

  const handlePlay = () => {
    setPlaying(true)
  }

  const handlePause = () => {
    setPlaying(false)
  }

  return (
    <div className="relative flex flex-col w-full h-full">
      <ReactPlayer
        ref={player}
        className="absolute top-0 left-0 peer"
        width="100%"
        height="100%"
        playing={playing}
        onPlay={handlePlay}
        onPause={handlePause}
        controls
        url={url}
        onReady={() => {
          const internalPlayer = player.current?.getInternalPlayer("hls")
          if (internalPlayer) {
            const storedQualityLevel = localStorage.getItem("video_quality")
            internalPlayer.currentLevel = parseInt(storedQualityLevel ?? "-1")
          }
        }}
        playsinline
        config={{
          file: {
            forceHLS: !isSafari,
            attributes: {
              width: "100%", 
              height: "100%", 
            },
            ...(token && {
              hlsOptions: {
                backBufferLength: 0,
                xhrSetup: (xhr: XMLHttpRequest, url: string) => {
                  if (!token || url.includes("s3")) return
                  xhr.setRequestHeader("Authorization", "Bearer " + token)
                },
              },
            }),
          },
        }}
      />
      <div
        className={clsxm(
          "opacity-0 z-20 m-2 mr-6 ml-auto peer-hover:opacity-100 hover:opacity-100 transition-opacity w-16",
          [!playing && "opacity-100"]
        )}
      >
        <Dropdown
          label=""
          renderTrigger={() => <span className="flex justify-end">
            <BsGear className="text-gray-300 mt-4 w-5 h-5 cursor-pointer" />
          </span>}>
          <Dropdown.Item
            onClick={() => handleSelectQualityLevel(-1)}
            className={clsx([qualityLevel === -1 && "bg-slate-500 text-white"])}
          >
            Auto
          </Dropdown.Item>
          {RESOLUTION_OPTIONS.map((option, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleSelectQualityLevel(index)}
              className={clsx([
                qualityLevel === index && "bg-slate-500 text-white",
              ])}
            >
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown>
      </div>
    </div>
  )
}

export default VideoPlayer
