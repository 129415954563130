import { useQuery } from "@tanstack/react-query"

import { getCourseCategoryQuestion } from "../functions"
import { CmsQueryKey, GetCourseCategoryQuestionParams } from "../../types"

const useGetCourseCategoryQuestion = (params?: GetCourseCategoryQuestionParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.CourseCategoryQuestion, params?.qParams ?? ""],
        queryFn: () => getCourseCategoryQuestion(params?.qParams),
        ...params?.options,
    })
}

export default useGetCourseCategoryQuestion
