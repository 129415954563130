import { GeneralCourseCategoryQuestionRequest, GeneralCourseCategoryQuestionResponse } from "utils/api/cms/types"
import { requestCMS } from "utils/axiosInstance"

const createCategoryQuestion = async (payload: GeneralCourseCategoryQuestionRequest) => {
    const res = await requestCMS.post<GeneralCourseCategoryQuestionResponse>(
        "/employee/course/exam",
        payload
    )
    return res
}

export default createCategoryQuestion
