import { requestCMS } from "utils/axiosInstance"
import {  GetCourseQuestionDetailResponse } from "../../types"

const getCourseQuestionDetail = async (exam_id: string) => {
    const res = await requestCMS.get<GetCourseQuestionDetailResponse>(
        `/employee/course/question?exam_id=${exam_id}`
    )
    return res
}

export default getCourseQuestionDetail;
