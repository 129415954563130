import React from "react"
import moment from "moment"
import Section from "components/ui/section/Section"
import ShowAllLink from "../../components/ShowAllLink"
import WorkshopCard from "../../components/WorkshopCard"
import { useGetWorkshops } from "utils/api/public/queries/hooks"
import CardHorizontalSkeleton from "components/public/components/CardHorizontalSkeleton"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const LatestWorkshop = () => {
  const { data: dataWorkshops, isLoading: isLoadingWorkshops } = useGetWorkshops({
    qParams: { limit: 6 },
  })

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0,
    },
  }

  return (
    <>
      {dataWorkshops?.data && dataWorkshops.data.length > 0 && (
        <Section className="my-12" withContainer>
          <Section.Title className="text-tertiary" position="left">
            Workshop
          </Section.Title>
          <div className="mb-10">
            {isLoadingWorkshops
              ? [...Array(6)].map((_, index) => (
                  <CardHorizontalSkeleton key={index} />
                ))
              : (
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className=""
                  containerClass="container-with-dots"
                  dotListClass=""
                  draggable
                  focusOnSelect
                  infinite={false}
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={responsive}
                  rewind={false}
                  rewindWithAnimation={false}
                  rtl={false}
                  shouldResetAutoplay
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  {dataWorkshops.data.map((workshop) => {
                    const formattedDate = moment(workshop.start_date).format(
                      "DD-MMMM"
                    )
                    const [date, month] = formattedDate.split("-")

                    return (
                      <WorkshopCard
                        key={workshop.id}
                        title={workshop.title}
                        currency={workshop.price.currency_text}
                        currentPrice={workshop.price.current_price_text}
                        masterPrice={workshop.price.master_price_text}
                        quota={workshop.quota}
                        startDate={workshop.start_date}
                        endDate={workshop.end_date}
                        slug={workshop.slug}
                        imgSrc={workshop.thumbnail}
                        speakersData={workshop.extras}
                      />
                    )
                  })}
                </Carousel>
              )}
          </div>
          <ShowAllLink to="/workshops">
            <span className="text-tertiary">Lihat Semua workshop</span>
          </ShowAllLink>
        </Section>
      )}
    </>
  )
}

export default LatestWorkshop
