import { requestCMS } from "utils/axiosInstance"
import type { DeleteCourseCategoryQuestionResponse } from "../../types"

const deleteCategoryCourseQuestion = async (courseCategoryID: string) => {
    const res = await requestCMS.delete<DeleteCourseCategoryQuestionResponse>(
        `/employee/course/exam/${courseCategoryID}`
    )
    return res
}

export default deleteCategoryCourseQuestion
