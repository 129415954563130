import { useMutation } from "@tanstack/react-query"

import {  DeleteCourseQuestionDetailResponse } from "../../types"
import { deleteQuestion } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationDeleteQuestion = (
    options?: GeneralMutationParams<DeleteCourseQuestionDetailResponse, string>
) => {
    return useMutation({
        mutationFn: (questionID) => deleteQuestion(questionID),
        ...options,
    })
}

export default useMutationDeleteQuestion
