import { useParams } from "react-router-dom"
import clsx from "clsx"
import moment from "moment"
import noImage from "assets/images/no-image.jpg"

import CardContentWorkshop from "components/public/workshop/detail-workshop/CardContentWorkshop"
import { useGetWorkshopDetail } from "utils/api/public/queries/hooks"
import WorkshopTitle from "components/public/workshop/detail-workshop/Title"
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import DOMPurify from "dompurify"

type Extras = {
  offline: boolean
  online: boolean
  speakers: string[]
  location: string
}

const WorkshopDetail = () => {
  const { slug } = useParams()

  const { data } = useGetWorkshopDetail({ slug: slug ?? "" })

  const dataWorkshop = data?.data
  const extras: Extras | undefined =
    dataWorkshop && JSON.parse(dataWorkshop.extras)

  return (
    <Section
      withContainer
      className="py-12"
    >
      <WorkshopTitle
        title={dataWorkshop?.title ?? ""}
        categories={dataWorkshop?.categories ?? []}
        startDate={`${moment(dataWorkshop?.start_date).format(
          "DD MMM YYYY"
        )}`}
        endDate={`${moment(dataWorkshop?.end_date).format(
          "DD MMM YYYY"
        )}`}
      />
      <div
        className={clsx(
          "flex flex-col-reverse lg:grid lg:grid-cols-10 gap-4",
          "py-4"
        )}
      >
        <div className="lg:col-span-7 flex flex-col gap-4">
          <div className="rounded-lg border p-6 flex flex-col gap-4">
            <Typography
              variant="subheading4"
              className=""
            >
              Overview
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(dataWorkshop?.description ?? "", {
                  USE_PROFILES: { svg: false, html: true },
                }),
              }}
            />
          </div>
          <div className="rounded-lg border p-6 flex flex-col gap-4">
            <Typography
              variant="subheading4"
              className=""
            >
              Term And Condition
            </Typography>
            <ol className="list-decimal list-inside font-semibold italic ">
              <li>
                Event ini terbuka untuk umum.
              </li>
              <li>
                Peserta diharapakan join ke grup WA dengan klik join grup setelah mendaftar atau pada dashboard.
              </li>
              <li>
                Segala informasi terkait Workshop (termasuk link room) akan dishare melalui grup whatsapp.
              </li>
              <li>
                Bagi yang mengajukan sertifikat peserta dapat download sertifikat pada dashboard maksimal H+1 acara
              </li>
              {/* <li>
                Event ini gratis. Tetapi jika ingin mendapatkan sertifikat serta akses materi, akan dikenakan biaya tambahan hanya sebesar Rp 15.000
              </li> */}
            </ol>
          </div>
        </div>
        <div className="lg:col-span-3">
          <CardContentWorkshop
            id={String(dataWorkshop?.id)}
            slug={dataWorkshop?.slug ?? ""}
            startDate={`${moment(dataWorkshop?.start_date).format(
              "DD MMM YYYY"
            )}`}
            endDate={`${moment(dataWorkshop?.end_date).format(
              "DD MMM YYYY"
            )}`}
            currency={dataWorkshop?.price.currency_text ?? ""}
            masterPrice={dataWorkshop?.price.master_price_text ?? ""}
            currentPrice={dataWorkshop?.price.current_price_text ?? ""}
            speakers={extras?.speakers ?? []}
            buyable={dataWorkshop?.buyable ?? false}
            quota={dataWorkshop?.quota}
            thumbnail={dataWorkshop?.thumbnail}
            offline={extras?.offline}
            online={extras?.online}
            location={extras?.location}
          />
        </div>
      </div>
    </Section>
  )
}

export default WorkshopDetail
