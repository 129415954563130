import { SubmitHandler, useForm, Controller } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { useMutationCreateQuestion } from "utils/api/cms/mutations/hooks"
import { GeneralQuestionRequest } from "utils/api/cms/types"
import {
    BookmarkIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"

const QuestionForm = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm<GeneralQuestionRequest>({})
    const { mutate: mutateCreate } =
        useMutationCreateQuestion({
            onSuccess(data) {
                if (!data.success) {
                    return
                }
                toast.success("pertanyaan berhasil dibuat")
                setTimeout(() => {
                    navigate("/cms/course/category-question/" + Number(props.segments[2]) + "/detail/" + Number(params.id))
                }, 1000)
            },
            onError(error) {
                toast.error(error.message)
            },
        })
    const onSubmit: SubmitHandler<GeneralQuestionRequest> = (data) => {
        let payload: GeneralQuestionRequest = {
            exam_id: Number(params.id),
            content: data.content,
        };
        return mutateCreate(payload);
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 pb-4 min-w-[42rem]"
        >
            <TextInput
                type="text"
                className="cms-base-input mt-2"
                labelClass="cms-base-label mt-4"
                isRequired
                label="Pertanyaan"
                errorMessage={errors.content?.message}
                errorIcon={
                    <ExclamationTriangleIcon
                        height={24}
                        width={24}
                        className="text-red-800"
                    />
                }
                {...register("content", {
                    required: {
                        value: true,
                        message: "Pertanyaan wajib diisi",
                    },
                })}
            />
            <ButtonWithIcon
                action={undefined}
                type="submit"
                class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
                disabled={isSubmitting}
            >
                <BookmarkIcon className="h-6 w-6 text-white" />
                <span>
                    {!isSubmitting ? (
                        <>{"Tambahkan"}</>
                    ) : (
                        "Menyimpan..."
                    )}
                </span>
            </ButtonWithIcon>
        </form>
    )
}

export default QuestionForm
