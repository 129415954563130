import { useMutation } from "@tanstack/react-query"

import {
    GeneralCourseCategoryQuestionResponse,
    GeneralCourseCategoryQuestionRequest,
} from "../../types"
import { createCategoryQuestion } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationCreateCategoryQuestion = (
    options?: GeneralMutationParams<
        GeneralCourseCategoryQuestionResponse,
        GeneralCourseCategoryQuestionRequest
    >
) => {
    return useMutation({
        mutationFn: (payload) => createCategoryQuestion(payload),
        ...options,
    })
}

export default useMutationCreateCategoryQuestion
