import { SubmitHandler, useForm, Controller } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import { useMutationGiveawayCourse } from "utils/api/cms/mutations/hooks"
import {
    BookmarkIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import clsx from "clsx"
import SelectInput from "components/ui/form/SelectInput"
import { useMemo } from "react"
import useGetCourseAll from "utils/api/cms/queries/hooks/useGetCourseAll"
import { GeneralGiveawayRequest } from "utils/api/cms/types"

type Option = {
    label: string
    value: string | number
}

interface UserGiveawayForm {
    user_id: number
    course_id: Option
}

const UserGiveawayForm = () => {
    const navigate = useNavigate()
    const params = useParams()

    const {
        handleSubmit,
        register,
        control,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<UserGiveawayForm>({})

    const { data: dataCourse } = useGetCourseAll({})


    const { mutate: mutateGiveaway } =
        useMutationGiveawayCourse({
            onSuccess(data) {
                if (!data.success) {
                    return
                }
                toast.success("Berhasil melakukan giveaway kelas")
                setTimeout(() => {
                    navigate("/cms/user/course/" + params.id)
                }, 1000)
            },
            onError(error) {
                toast.error(error.message)
            },
        })

    const courseOptions = useMemo(
        () =>
            dataCourse?.data.map((course) => ({
                label: course.title,
                value: course.id,
            })),
        [dataCourse]
    )

    const onSubmit: SubmitHandler<UserGiveawayForm> = (data) => {
        const payload: GeneralGiveawayRequest = {
            user_id: Number(params.id),
            course_id: data.course_id ? String(data.course_id.value) : ""
        }

        return mutateGiveaway(payload)
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 pb-4 min-w-[42rem]"
        >
            <div className="sm:col-span-2">
                <Controller
                    control={control}
                    name="course_id"
                    rules={{
                        required: { value: true, message: "Kelas wajib dipilih" },
                    }}
                    render={({ field }) => (
                        <SelectInput
                            {...field}
                            label="Pilih Kelas"
                            labelClass="cms-base-label"
                            errorMessage={errors.course_id?.message}
                            errorIcon={
                                <ExclamationTriangleIcon
                                    height={24}
                                    width={24}
                                    className="text-red-800"
                                />
                            }
                            classNames={{
                                container: () => "max-w-2xl",
                            }}
                            options={courseOptions}
                            isRequired
                        />
                    )}
                />

            </div>
            <ButtonWithIcon
                action={undefined}
                type="submit"
                class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
                disabled={isSubmitting}
            >
                <BookmarkIcon className="h-6 w-6 text-white" />
                <span>
                    {!isSubmitting ? (
                        <>{"Simpan"}</>
                    ) : (
                        "Menyimpan..."
                    )}
                </span>
            </ButtonWithIcon>
        </form>
    )
}

export default UserGiveawayForm
