import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentPlusIcon,
  EyeIcon,
  InformationCircleIcon,
  NoSymbolIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid"
import { createColumnHelper } from "@tanstack/react-table"
import to from "await-to-js"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom"
import CMSDefaultTableListFilter from "../../../components/cms/CMSDefaultTableListFilter"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import ButtonWithIcon from "../../../components/ui/button/ButtonWithIcon"
import DarkPagination from "../../../components/ui/pagination/DarkPagination"
import ReactTable from "../../../components/ui/react-table/ReactTable"
import useFlashMessage from "../../../hooks/useFlashMessage"
import { toast } from "react-toastify"
import { Tooltip } from "flowbite-react"
import Button from "components/ui/button/Button"
import usePagination from "../../../hooks/usePagination"
import api from "../../../utils/axiosInstance"
import { getCMSSegments } from "../../../utils/helper"
import { CMSGetReferralsResponse, Referral } from "../../../utils/types"
import { useMutationDisableReferral } from "utils/api/cms/mutations/hooks"
import Modal from "components/ui/modal/Modal"
import Typography from "components/ui/typography"

const ReferralManagement: React.FC = () => {
  const segments = getCMSSegments()
  const navigate = useNavigate()
  const loaderResponse = useLoaderData() as CMSGetReferralsResponse<Referral>
  const [referralsResponse, setReferralsResponse] =
    useState<CMSGetReferralsResponse<Referral>>()
  const referralsFetcher = async () => {
    const page = Math.min(currentPage + 1, totalPages)
    const [_, resultResponse] = await to<CMSGetReferralsResponse<Referral>>(
      api.cms.getReferralList({
        page: page,
        keyword: keyword,
        limit: perPage,
      })
    )

    setTotalPages(resultResponse!.meta!.last_page)
    setReferralsResponse(resultResponse)
  }
  const [keyword, setKeyword] = useState("")
  const [perPage, setPerpage] = useState(10)
  const [modalDisable, setModalDisable] = useState<{
    isOpen: boolean
    referralID: string
    referralName: string
  }>()
  const {
    totalPages,
    currentPage,
    initialPage,
    forced,
    setTotalPages,
    setCurrentPage,
    setInitialPage,
    setShouldFetch,
    setForced,
  } = usePagination(referralsFetcher, [keyword, perPage])
  useFlashMessage(
    "success",
    "referralUpsertSuccess",
    "Berhasil menyimpan referral"
  )

  const { mutate: mutateDisableReferral } = useMutationDisableReferral({
    onSuccess(data, variables) {
      toast.success("Berhasil deactivate kelas")
      handleModalDisable()
      setReferralsResponse((prev) => {
        if (!prev) return
        return {
          ...prev,
          data: prev.data.map((referral) => {
            if (referral.id.toString() === variables.referralID) {
              return {
                ...referral,
                ...data.data,
              }
            }
            return referral
          }),
        }
      })
    },
  })

  const handleModalDisable = (referralID?: string, referralName?: string) => {
    setModalDisable((prev) => ({
      isOpen: !prev?.isOpen,
      referralID: referralID ?? "",
      referralName: referralName ?? "",
    }))
  }

  const referralNameSearchHandler = (keywordVal) => {
    setForced(true)
    setCurrentPage(0)
    setKeyword(keywordVal)
  }

  const perPageHandler = (selected: number) => {
    setForced(true)
    setCurrentPage(0)
    setPerpage(selected)
  }

  useEffect(() => {
    if (!loaderResponse) return

    setReferralsResponse(loaderResponse)
    setTotalPages(loaderResponse.meta!.last_page)

    if (loaderResponse.meta!.current_page > 1) {
      setInitialPage(loaderResponse.meta!.current_page - 1)
    }

    setShouldFetch(true)
  }, [loaderResponse])

  const colHelper = createColumnHelper<Referral>()
  const cols = [
    colHelper.accessor("name", {
      header: "Nama Referral",
    }),
    colHelper.accessor("code", {
      header: "Kode Referral",
    }),
    colHelper.accessor("value", {
      header: "Potongan Harga",
    }),
    colHelper.accessor("courses", {
      header: "Jumlah Modul Belajar",
      cell: (props) => (
        <div className="inline-flex items-center gap-2">
          {props.getValue().length}
          {props.row.original.courses.length > 0 && (
            <Tooltip
              content={props.row.original.courses
                .map((course) => course.title)
                .join(", ")}
            >
              <InformationCircleIcon
                width={16}
                height={16}
              />
            </Tooltip>
          )}
        </div>
      ),
    }),
    colHelper.accessor("is_active", {
      header: "Status Referral",
      cell: (props) => {
        if (!props.getValue()) {
          return (
            <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-yellow-900 text-yellow-300">
              PENDING
            </span>
          )
        }

        return (
          <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-green-900 text-green-300">
            Aktif
          </span>
        )
      },
    }),
    colHelper.accessor("start_active_at", {
      header: () => "Periode Aktif",
      cell: (props) =>
        `${moment(props.getValue()).locale("id").format("DD MMMM YYYY")}-
      ${moment(props.row.original.valid_until).format("DD MMMM YYYY")}`
    }),
    // colHelper.accessor("updated_at", {
    //   header: () => "Tanggal Terakhir Diupdate",
    //   cell: (props) =>
    //     moment(props.getValue()).locale("id").format("DD MMMM YYYY"),
    // }),
    colHelper.display({
      id: "actions",
      header: () => "AKSI",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <div className="flex flex-row w-fit space-x-3">
            <Tooltip content="Edit">
              <ButtonWithIcon
                class="mx-auto border-2 border-transparent bg-yellow-400 text-white hover:bg-yellow-300 hover:border-yellow-300"
                type="button"
                action={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  return navigate(`edit/${props.row.original.id}`)
                }}
                sizeClass="p-1"
              >
                <PencilSquareIcon className="h-5 w-5" />
              </ButtonWithIcon>
            </Tooltip>
            <Tooltip content="View">
              <ButtonWithIcon
                class="mx-auto border-2 border-transparent bg-purple-400 text-white hover:bg-purple-300 hover:border-purple-300"
                type="button"
                action={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  return navigate(`transaction/${props.row.original.id}`)
                }}
                sizeClass="p-1"
              >
                <EyeIcon className="h-5 w-5" />
              </ButtonWithIcon>
            </Tooltip>
            {props.row.original.is_active && (
              <Tooltip content="Deactivate">
                <ButtonWithIcon
                  class="mx-auto border-2 border-transparent bg-red-600 text-white hover:bg-red-500 hover:border-red-500"
                  type="button"
                  action={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    handleModalDisable(
                      props.row.original.id.toString(),
                      props.row.original.name
                    )
                  }}
                  sizeClass="p-1"
                >
                  <NoSymbolIcon className="h-5 w-5" />
                </ButtonWithIcon>
              </Tooltip>
            )}
          </div>
        </div>
      ),
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide my-2">
        Pengaturan Referral
      </h2>
      <div className="flex flex-wrap justify-between gap-4 mb-4">
        <ButtonWithIcon
          action={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            return navigate("create")
          }}
          type="submit"
          class="border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        >
          <DocumentPlusIcon className="h-6 w-6 text-white" />
          <span>Tambah Referral</span>
        </ButtonWithIcon>
        <CMSDefaultTableListFilter
          pageSelector
          searchPlaceHolder="Cari nama konten belajar"
          wrapperClass="flex flex-row space-x-6"
          onSearchHandler={referralNameSearchHandler}
          perPageHandler={perPageHandler}
        />
      </div>
      {referralsResponse && (
        <>
          <ReactTable
            data={referralsResponse.data}
            columns={cols}
          />
          <div className="flex pb-8 items-center ml-auto">
            <DarkPagination
              nexLabel={<ChevronRightIcon className="h-5 w-5 text-gray-400" />}
              previousLabel={
                <ChevronLeftIcon className="h-5 w-5 text-gray-400" />
              }
              pageCount={totalPages}
              pageRangeDisplayed={2}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              initialPage={initialPage}
              forcePage={forced ? currentPage : undefined}
            />
          </div>
        </>
      )}

      <Modal
        header="Disable Referral"
        onclose={handleModalDisable}
        show={!!modalDisable?.isOpen}
        body={
          <Typography className="text-white">
            Apakah Anda yakin untuk disable referral{" "}
            <b>{modalDisable?.referralName}</b>?
          </Typography>
        }
        footer={
          <div className="space-x-2 ml-auto">
            <Button
              onClick={() => handleModalDisable()}
              variant="outlined"
              className="text-gray-300 border-gray-300"
            >
              Batal
            </Button>
            <Button
              onClick={() =>
                mutateDisableReferral({
                  referralID: modalDisable?.referralID ?? "",
                })
              }
            >
              Disable
            </Button>
          </div>
        }
      />
    </EmployeeDashboardWrapper>
  )
}

export default ReferralManagement

export const referralManagementLoader: LoaderFunction = async () => {
  const searchParams = new URLSearchParams(window.location.search)
  const response = await api.cms.getReferralList({
    keyword: searchParams.has("search")
      ? (searchParams.get("search") as string)
      : "",
    page: searchParams.has("page") ? +(searchParams.get("page") as string) : 1,
    limit: 10,
  })

  return response
}
