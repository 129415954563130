import { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
    DocumentPlusIcon,
    EyeIcon,
    TrashIcon,
} from "@heroicons/react/24/solid"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import { Tooltip } from "flowbite-react"
import Modal from "components/ui/modal/Modal"
import ReactTable from "components/ui/react-table/ReactTable"
import Button from "components/ui/button/Button"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import Typography from "components/ui/typography"
import { toast } from "react-toastify"

import CMSDefaultTableListFilter from "../../CMSDefaultTableListFilter"
import { useGetChoiceQuestion, useGetCourseQuestionDetail } from "utils/api/cms/queries/hooks"
import { CourseChoice } from "utils/types"
import { useMutationChoiceQuestionDelete } from "utils/api/cms/mutations/hooks"

const ChoiceList = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 1,
    })
    const [lastPage, setLastPage] = useState(1)
    const [searchFilter, setSearchFilter] = useState("")
    const [perPageLimit, setPerPageLimit] = useState(10)
    const [dataCategory, setDataCategory] = useState<CourseChoice[]>([])

    const [modalDelete, setModalDelete] = useState({
        open: false,
        title: "",
        choiceID: "",
    })
    const { data: resultResponse } = useGetChoiceQuestion(
        {
            question_id: params.id || " "
        })
    console.log(params.id)
    console.log(resultResponse?.data)

    useEffect(() => {
        if (resultResponse?.data && Array.isArray(resultResponse.data)) {
            setDataCategory(resultResponse.data);
        }
    }, [resultResponse]);

    const handleModalDelete = (choiceID?: string, title?: string) => {
        setModalDelete((prev) => ({
            open: !prev.open,
            choiceID: choiceID ?? "",
            title: title ?? "",
        }))
    }

    const { mutate: mutateDeleteCategory, isLoading } = useMutationChoiceQuestionDelete({
        onSuccess(data, variables) {
            handleModalDelete()
            toast.success("Berhasil hapus kategori course")

            setDataCategory((prev) => prev.filter((d) => d.id !== Number(variables)));
        },
    })

    const categoryColumnHelper = createColumnHelper<CourseChoice>()

    const categoryColumns = [
        categoryColumnHelper.accessor("name", {
            header: "Jawaban",
            cell: (props) => props.getValue() ?? '-',
        }),
        categoryColumnHelper.accessor("is_true", {
            header: "Status",
            cell: (props) => props.getValue() ? 'Benar' : 'Salah',
        }),
        categoryColumnHelper.display({
            id: "actions",
            header: "Aksi",
            cell: (props) => (
                <div className="flex flex-row w-fit space-x-3">
                    <Tooltip content="Delete">
                        <ButtonWithIcon
                            class="mx-auto border-2 border-transparent bg-red-600 text-white hover:bg-red-500 hover:border-red-500"
                            type="button"
                            action={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault()
                                handleModalDelete(
                                    props.row.original.id.toString(),
                                    props.row.original.name.toString(),
                                )
                            }}
                            sizeClass="p-1"
                        >
                            <TrashIcon className="h-5 w-5" />
                        </ButtonWithIcon>
                    </Tooltip>
                </div>
            ),
        }),
    ]

    const handleSearch = (keyword: string) => {
        setSearchFilter(keyword)
        setPagination((prevPagination) => ({ ...prevPagination, pageIndex: 0 }))
    }

    return (
        <>
            <div className="flex flex-wrap justify-between gap-4 my-6">
                <Button
                    as={Link}
                    to="create"
                    className="border-2 flex items-center border-transparent bg-primary text-white gap-2 hover:bg-transparent hover:text-white hover:border-primary"
                >
                    <DocumentPlusIcon className="h-6 w-6 text-white" />
                    <span>Tambah Pertanyaan</span>
                </Button>
                <CMSDefaultTableListFilter
                    pageSelector
                    searchPlaceHolder="Cari pertanyaan"
                    wrapperClass="ml-auto flex flex-row space-x-6 "
                    onSearchHandler={handleSearch}
                    perPageHandler={(selected) => setPerPageLimit(selected)}
                />
            </div>
            <ReactTable
                columns={categoryColumns}
                data={dataCategory ?? []}
                pagination={pagination}
                onPaginationChange={setPagination}
                pageCount={lastPage}
            />
            <Modal
                header="Hapus Data"
                onclose={handleModalDelete}
                show={modalDelete.open}
                body={
                    <Typography className="text-white">
                        Apakah Anda yakin ingin menghapus data <b>{modalDelete.title}</b>?
                    </Typography>
                }
                footer={
                    <div className="space-x-2 ml-auto">
                        <Button
                            onClick={() => handleModalDelete()}
                            isLoading={isLoading}
                            variant="outlined"
                            className="text-gray-300 border-gray-300"
                        >
                            Batal
                        </Button>
                        <Button onClick={() => mutateDeleteCategory(modalDelete.choiceID)}>
                            Hapus
                        </Button>
                    </div>
                }
            />

        </>
    )
}

export default ChoiceList
