import { useEffect, useRef } from "react"
import { AcademicCapIcon } from "@heroicons/react/24/solid"
import DOMPurify from "dompurify"
import noImage from "assets/images/no-image.jpg"
import { useIntersectionObserver } from "usehooks-ts"

import moment from "moment"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Card from "components/ui/card/horizontal"
import { useInfiniteGetPurchasedWebinars } from "utils/api/user/queries/hooks"
import Button from "components/ui/button/Button"
import Typography from "components/ui/typography"
import { Link } from "react-router-dom"
import SertifikatDownload from "components/public/components/WorkshopSertifikat"
const Workshops = () => {
  const ref = useRef<HTMLParagraphElement | null>(null)
  const entry = useIntersectionObserver(ref, {})

  const {
    data: dataPurchasedWorkshops,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteGetPurchasedWebinars({
    options: {
      staleTime: 0,
    },
    qParams: {
      limit: 10,
    },
  })

  useEffect(() => {
    if (!entry?.isIntersecting) return
    fetchNextPage()
  }, [entry])

  const isWorkshopEnded = (endDate: Date) => {
    return moment(endDate).isBefore(moment());
  }

  return (
    <div className="space-y-4">
      {dataPurchasedWorkshops?.pages.map((page) =>
        page.data.map((workshop) => (
          <Card
            key={workshop.id}
            // to={`/my-workshop/${workshop.slug}`}
            className="flex flex-col lg:flex-row justify-between w-full lg:h-[180px]"
            thumbnail={workshop.thumbnail ?? noImage}
          // height={130}
          >
            <div className="flex flex-col justify-between max-sm:mt-4">
              <div className="flex gap-2">
                <Card.Title className="text-tertiary capitalize">
                  {workshop.title}
                </Card.Title>
                <Typography variant="text2" className="hidden lg:block font-medium w-1/3">
                  {`${moment(workshop.start_date).format("DD MMM YYYY")}`}
                </Typography>
              </div>
              <div className="flex flex-col gap-2">
                <div className="grid gap-2">
                  <Typography variant="text3" className="font-bold text-primary">
                    Mentor
                  </Typography>
                  {workshop.mentor && (
                    <Typography
                      as="h4"
                      variant="text3"
                      className="font-semibold text-tertiary"
                    >
                      {(() => {
                        const mentorData = JSON.parse(workshop.mentor);
                        const speakers = mentorData.speakers;
                        if (Array.isArray(speakers) && speakers.length > 1) {
                          return speakers.join(", ");
                        } else {
                          return speakers;
                        }
                      })()}
                    </Typography>
                  )}

                </div>
                {/* <SertifikatDownload
                  className="hidden"
                  dataCourse={workshop.title}
                  dataTime={`${moment(workshop.end_date).format("DD MMM YYYY")}`}
                /> */}
                <div className="flex">
                  <Link
                    to={`/my-workshop/${workshop.slug}`}
                  >
                    <Button
                      size="sm"
                      className={`mr-2 lg:px-5 py-2 rounded-md text-sm ${isWorkshopEnded(workshop.end_date) ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-300 cursor-not-allowed'}`}
                      disabled={!isWorkshopEnded(workshop.end_date)}
                    >
                      Download Sertifikat
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        ))
      )}
      {hasNextPage && (
        <div
          ref={ref}
          className="flex justify-center items-center w-[522px]"
        >
          {isFetchingNextPage ? (
            <Typography className="text-primary">Muat Lebih...</Typography>
          ) : (
            <Button
              variant="outlined"
              onClick={() => fetchNextPage()}
            >
              Muat Lebih
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default Workshops
