import { requestUser } from "utils/axiosInstance"
import { PublicSubmitResetScoreResponse, ResetScorePayload } from "../../types"

const resetScore = async (payload: ResetScorePayload) => {
    const res = await requestUser.post<PublicSubmitResetScoreResponse>(
        "/user/course/question/restart",
        payload
    )
    return res
}

export default resetScore
