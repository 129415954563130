import to from "await-to-js"
import React, { useEffect, useState } from "react"
import { SubmitHandler } from "react-hook-form"
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import ReferralManagementForm, {
  ReferralForm,
} from "../../../components/cms/ReferralManagementForm"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import api from "../../../utils/axiosInstance"
import { getCMSSegments } from "../../../utils/helper"
import {
  CMSCourseListResponse,
  CMSCreateReferralResponse,
  Course,
  GeneralAPIValidationResponse,
} from "../../../utils/types"
import moment from "moment"

const ReferralManagementCreate: React.FC = () => {
  const loaderResponse = useLoaderData() as CMSCourseListResponse<Course>
  const [courseOptions, setCourseOptions] = useState<
    {
      label: string
      value: number
    }[]
  >([])
  const segments = getCMSSegments()
  const navigate = useNavigate()
  const [referralSubmitting, setReferralSubmitting] = React.useState(false)
  const [serverValidationError, setServerValidation] = useState<{
    name: string | undefined
    code: string | undefined
    value: number | undefined
    is_active: boolean | undefined
    start_active_at: string | undefined
    valid_until: string | undefined
    course_ids: number[] | undefined
  }>({
    name: undefined,
    code: undefined,
    value: undefined,
    is_active: undefined,
    start_active_at: undefined,
    valid_until: undefined,
    course_ids: undefined,
  })

  const referralSubmitHandler: SubmitHandler<ReferralForm> = async (data) => {
    setReferralSubmitting(true)
    const [err, _] = await to<
      CMSCreateReferralResponse,
      GeneralAPIValidationResponse
    >(api.cms.createReferral(data))

    if (!err) {
      navigate("/cms/master/referral", {
        state: { referralUpsertSuccess: true },
      })
      return
    }

    if (!err.hasOwnProperty("errors")) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.message,
      })

      setReferralSubmitting(false)

      return
    }

    const validationResponse = { ...serverValidationError }

    Object.keys(validationResponse).forEach((field) => {
      const errMessages: string[] | undefined = err!.errors![field]

      if (errMessages === undefined) return

      const message = (errMessages as string[]).join(". ")

      validationResponse[field] = message
    })

    setServerValidation(validationResponse)

    setReferralSubmitting(false)
  }

  useEffect(() => {
    if (!loaderResponse) return
    const options = loaderResponse.data.map((course) => {
      return { label: course.title, value: course.id }
    })
    setCourseOptions(options)
  }, [loaderResponse])

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        prefix="cms"
        segments={segments}
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Buat Referral Baru
      </h2>
      {courseOptions && (
        <ReferralManagementForm
          method="POST"
          onSubmitHandler={referralSubmitHandler}
          serverValidationResponse={serverValidationError}
          submitting={referralSubmitting}
          categoryOptions={courseOptions}
        />
      )}
    </EmployeeDashboardWrapper>
  )
}

export default ReferralManagementCreate

export const referralCreateLoader: LoaderFunction = async () => {
  const coursesResponse = await api.cms.getAllActiveCourses()

  return coursesResponse
}
