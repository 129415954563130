import { Helmet } from "react-helmet"

import ListWorkshop from "components/public/workshop/list-workshop"
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import clsxm from "utils/clsxm"

const Workshops = () => {
  return (
    <>
      <Helmet>
        <title>Workshops</title>
      </Helmet>
      <div className="container">
        <div className={clsxm("mt-8 lg:text-left ")}>
          <Typography
            variant="heading6"
            as="h1"
            className="text-tertairy mb-4"
          >
            Ikuti Program Workshop dari BeePlus Institute Sekarang!
          </Typography>
          <Typography
            variant="text2"
            className="font-medium"
          >
            Kelas interaktif untuk mengembangkan skill secara online bersama mentor berpengalaman.
          </Typography>
        </div>
        <Section
          className="my-12"
        >
          <ListWorkshop />
        </Section>
      </div>
    </>
  )
}

export default Workshops
