import { requestUser } from "utils/axiosInstance"
import type { GetCourseSectionQuizResponse } from "../../types"

const getCourseSectionQuiz = async (course_id: string) => {
  const res = await requestUser.get<GetCourseSectionQuizResponse>(
    `/user/course-quiz?course_id=${course_id}`
  )
  return res
}

export default getCourseSectionQuiz
