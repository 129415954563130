import { SubmitHandler, useForm, Controller } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { useMutationCreateCategoryQuestion } from "utils/api/cms/mutations/hooks"
import { GeneralCourseCategoryQuestionRequest } from "utils/api/cms/types"
import {
    BookmarkIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import useGetCourseSection from "utils/api/cms/queries/hooks/useGetCourseSection"
import { useMemo } from "react"
import SelectInput from "components/ui/form/SelectInput"

type Option = {
    label: string
    value: string | number
}

const TYPE_OPTIONS = [
    {
        label: "PRE",
        value: 1,
    },
    {
        label: "MID",
        value: 2,
    },
    {
        label: "POST",
        value: 3,
    },
]

interface CareerCategoryForm {
    name: string
    type: Option
    section_id?: Option
}

const CategoryQuestionForm = () => {
    const navigate = useNavigate()
    const params = useParams()

    const {
        handleSubmit,
        register,
        control,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<CareerCategoryForm>({})

    const { data: dataSection } = useGetCourseSection({
        options: {
            staleTime: 0,
        },
        qParams: {
            course_id: Number(params.id)
        },
    })

    const sectionOptions = useMemo(
        () =>
            dataSection?.data.map((course) => ({
                label: course.title,
                value: course.id,
            })),
        [dataSection]
    )

    const { mutate: mutateCreate } =
        useMutationCreateCategoryQuestion({
            onSuccess(data) {
                if (!data.success) {
                    return
                }
                toast.success("Kategori pertanyaan berhasil dibuat")
                setTimeout(() => {
                    navigate("/cms/course/category-question/" + Number(params.id))
                }, 1000)
            },
            onError(error) {
                toast.error(error.message)
            },
        })

    const onSubmit: SubmitHandler<CareerCategoryForm> = (data) => {
        let payload: GeneralCourseCategoryQuestionRequest = {
            course_id: Number(params.id),
            name: data.name,
            type: data.type ? Number(data.type.value) : 0,
        };


        if (data.section_id && data.section_id.value) {
            payload.section_id = Number(data.section_id.value);
        }

        return mutateCreate(payload);
    }



    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 pb-4 min-w-[42rem]"
        >
            <TextInput
                type="text"
                className="cms-base-input mt-2"
                labelClass="cms-base-label mt-4"
                isRequired
                label="Nama"
                errorMessage={errors.name?.message}
                errorIcon={
                    <ExclamationTriangleIcon
                        height={24}
                        width={24}
                        className="text-red-800"
                    />
                }
                {...register("name", {
                    required: {
                        value: true,
                        message: "Nama kategori wajib diisi",
                    },
                })}
            />
            <div className="sm:col-span-2">
                <Controller
                    control={control}
                    name="type"
                    rules={{
                        required: { value: true, message: "Tipe wajib dipilih" },
                    }}
                    render={({ field }) => (
                        <SelectInput
                            {...field}
                            label="Pilih Tipe"
                            labelClass="cms-base-label"
                            errorMessage={errors.type?.message}
                            errorIcon={
                                <ExclamationTriangleIcon
                                    height={24}
                                    width={24}
                                    className="text-red-800"
                                />
                            }
                            classNames={{
                                container: () => "max-w-2xl",
                            }}
                            options={TYPE_OPTIONS}
                            isRequired
                        />
                    )}
                />
            </div>
            <div className="sm:col-span-2">
                <Controller
                    control={control}
                    name="section_id"
                    render={({ field }) => (
                        <SelectInput
                            {...field}
                            label="Pilih Bagian Kategori"
                            labelClass="cms-base-label"
                            errorMessage={errors.section_id?.message}
                            classNames={{
                                container: () => "max-w-2xl",
                            }}
                            options={sectionOptions}
                        />
                    )}
                />
            </div>
            <ButtonWithIcon
                action={undefined}
                type="submit"
                class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
                disabled={isSubmitting}
            >
                <BookmarkIcon className="h-6 w-6 text-white" />
                <span>
                    {!isSubmitting ? (
                        <>{"Tambahkan"}</>
                    ) : (
                        "Menyimpan..."
                    )}
                </span>
            </ButtonWithIcon>
        </form>
    )
}

export default CategoryQuestionForm
