
import { useMutation } from "@tanstack/react-query"
import { GeneralMutationParams } from "utils/types"
import { GeneralQuestionRequest, GeneralQuestionResponse } from "utils/api/cms/types"
import { createQuestion } from "../../functions"

const useMutationCreateQuestion = (
    options?: GeneralMutationParams<
        GeneralQuestionResponse,
        GeneralQuestionRequest
    >
) => {
    return useMutation({
        mutationFn: (payload) => createQuestion(payload),
        ...options,
    })
}

export default useMutationCreateQuestion
