import { useMutation } from "@tanstack/react-query"

import {
  GeneralGiveawayResponse,
  GeneralGiveawayRequest,
} from "../../types"
import { giveaway } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationGiveawayCourse = (
  options?: GeneralMutationParams<
    GeneralGiveawayResponse,
    GeneralGiveawayRequest
  >
) => {
  return useMutation({
    mutationFn: (payload) => giveaway(payload),
    ...options,
  })
}

export default useMutationGiveawayCourse
