import { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
    DocumentPlusIcon,
} from "@heroicons/react/24/solid"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import ReactTable from "components/ui/react-table/ReactTable"
import Button from "components/ui/button/Button"
import { useGetUserCourseList } from "utils/api/cms/queries/hooks"
import type { UserCourse } from "utils/api/cms/types"
import { CMSUserCourseListResponse } from "utils/types"

const UserCourseList = () => {
    const params = useParams();
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 1,
    })
    const [lastPage, setLastPage] = useState(1)
    const [userResponse, setUserResponse] =
        useState<CMSUserCourseListResponse<UserCourse>>()
    const [perPageLimit, setPerPageLimit] = useState(10)
    const [dataUser, setDataUser] = useState<UserCourse[]>([])

    const { data: resultResponse } = useGetUserCourseList({
        options: {
            staleTime: 0,
        },
        qParams: {
            limit: perPageLimit,
            page: pagination.pageIndex + 1,
            user_id: Number(params.id)
        },
    })

    useEffect(() => {
        setUserResponse(resultResponse)
        setDataUser(resultResponse?.data || [])
        setLastPage(resultResponse?.meta?.last_page || 1)
    }, [resultResponse])

    const userColumnHelper = createColumnHelper<UserCourse>()

    const userColumns = [
        userColumnHelper.accessor("course.title", {
            header: "Judul",
            cell: (props) => props.getValue(),
        }),
        userColumnHelper.accessor("course.partnerships.full_name", {
            header: "Partnership",
            cell: (props) => props.getValue() || "-",
        }),
        userColumnHelper.accessor("course.lecturer", {
            header: "Mentor",
            cell: (props) => props.getValue() || "-",
        })
    ]

    return (
        <>
            <div className="flex flex-wrap justify-between gap-4 my-6">
                <Button
                    as={Link}
                    to="create"
                    className="border-2 flex items-center border-transparent bg-primary text-white gap-2 hover:bg-transparent hover:text-white hover:border-primary"
                >
                    <DocumentPlusIcon className="h-6 w-6 text-white" />
                    <span>Tambah Giveaway</span>
                </Button>
            </div>
            <ReactTable
                columns={userColumns}
                data={dataUser ?? []}
                pagination={pagination}
                onPaginationChange={setPagination}
                pageCount={lastPage}
            />
        </>
    )
}

export default UserCourseList
