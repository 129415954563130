import { Helmet } from "react-helmet"

/** Components */
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import ListCourse from "components/public/course/list-course"
import CourseCard from "components/public/components/CourseCard"

/** Utils */
import { useGetCourses } from "utils/api/public/queries/hooks"
import clsxm from "utils/clsxm"
import CardVerticalSkeleton from "components/public/components/CardVerticalSkeleton"

const Courses = () => {
  const { data: dataLatestCourses, isLoading: isLoadingLatestCourses } =
    useGetCourses({ qParams: { limit: 4 } })

  return (
    <>
      <Helmet>
        <title>Kelas</title>
      </Helmet>
      <div className="container relative">
        <div className={clsxm("mt-8 lg:text-left ")}>
          <Typography
            variant="heading6"
            as="h1"
            className="text-tertairy mb-4"
          >
            Temukan Dirimu dalam Beragam Kategori Kursus Kami!
          </Typography>
          <Typography
            variant="text2"
            className="font-medium"
          >
            Telusuri Koleksi Kursus Kami untuk Menemukan Pelajaran yang Menyelami Minat dan Potensi Anda, Menggali Pengetahuan serta Keterampilan yang Anda Cari
          </Typography>
        </div>
        <Section
          className="my-12"
        >
          <ListCourse />
        </Section>
      </div>
    </>
  )
}

export default Courses
