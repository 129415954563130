import { GeneralQuestionRequest, GeneralQuestionResponse } from "utils/api/cms/types"
import { requestCMS } from "utils/axiosInstance"

const createQuestion = async (payload: GeneralQuestionRequest) => {
    const res = await requestCMS.post<GeneralQuestionResponse>(
        "/employee/course/question",
        payload
    )
    return res
}

export default createQuestion
