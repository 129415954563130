import { ClockIcon, VideoCameraIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"

import Card from "components/ui/card/vertical"
import Typography from "components/ui/typography"
import noImage from "assets/images/no-image.jpg"
import { Badge } from "flowbite-react"

interface CourseCardProps {
  to: string
  imgSrc: string
  title: string
  currency: string
  masterPrice: string
  currentPrice: string
  // totalDuration: string
  totalVideo: string
  lecturer: string
  width?: number
  height?: number
  className?: string
}

const CourseCard = ({
  to,
  imgSrc,
  title,
  currency,
  masterPrice,
  currentPrice,
  // totalDuration,
  totalVideo,
  lecturer,
  width,
  height,
  className
}: CourseCardProps) => {
  const masterPriceNumeric = parseFloat(masterPrice)
  const currentPriceNumeric = parseFloat(currentPrice)
  const isDiscount = masterPriceNumeric !== currentPriceNumeric
  const discountPercentage = isDiscount
    ? ((masterPriceNumeric - currentPriceNumeric) / masterPriceNumeric) * 100
    : 0

  return (
    <Card
      to={to}
      imgSrc={imgSrc ?? noImage}
      imgAlt={title}
      height={height ?? 370}
      width={width ?? 292}
      scale
      className={className}
    >
      <div>
        <Card.Title
          as="p"
          className="space-x-1 text-primary flex"
        >
          <span>
            {currency}
            {currentPrice}
          </span>

          {isDiscount && (
            <span className="line-through decoration-2 text-gray-400 font-medium">
              {currency}
              {masterPrice}
            </span>
          )}
          {isDiscount && (
            <Badge color="failure">{discountPercentage.toFixed(0)}%</Badge>
          )}
        </Card.Title>
        <Card.Title>{title}</Card.Title>
      </div>
      <div className={clsx("flex items-center gap-2")}>
        <div>
          <Typography
            variant="text4"
            className="font-bold"
          >
            Mentor
          </Typography>
          <Typography
            as="h4"
            variant="text4"
            className="font-semibold text-tertiary"
          >
            {lecturer ?? "-"}
          </Typography>
        </div>
      </div>
      <div className="flex gap-3 text-typo-gray">
        <Card.Caption
          icon={
            <VideoCameraIcon
              height={16}
              width={16}
            />
          }
          text={totalVideo}
        />
      </div>
    </Card>
  )
}

export default CourseCard
