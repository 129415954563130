import { UseQueryOptions } from "@tanstack/react-query"
import type {
  Course,
  GeneralApiResponse,
  GeneralQueryParams,
  Webinar,
  GeneralTransactionQueryParams,
  ProductPrice,
  ProductCategory,
  CourseSection,
  CourseSectionQuiz,
  CourseChoice,
} from "utils/types"

export enum CmsQueryKey {
  DiscountDetail = "discount-detail",
  DiscountCourse = "dicount-course",
  DiscountWebinar = "dicount-webinar",
  DiscountCertification = "dicount-certification",
  Referral = "referral",
  ReferralDetail = "referral-detail",
  Courses = "courses",
  CourseCategory = "course-category",
  CourseSection = "course-section",
  CourseCategoryQuestion = "course-category-question",
  CourseQuestionDetail = "course-question-detail",
  choiceQuestion = "choice-question",
  Webinars = "webinars",
  Users = "users",
  CareerCategories = "career-categories",
  Careers = "careers",
  UserCourse = "user-course",
  CareerUsers = "career_users",
  CareerDetail = "career-detail",
  WebinarDetail = "webinar-detail",
  WebinarPeserta = "webinar-peserta",
  Dashboard = "dashboard",
  Partnership = "partnership",
  Videos = "videos",
  VideoDetail = "video-detail",
  MaxSequence = "max-sequence",
  Transactions = "transactions",
  Slide = "slide",
  Sertifikat = "sertifikat",
  SertifikatProgram = "sertifikat-program",
  SertifikatUser = "sertifikat-user",
  SertifikatSkkni = "sertifikat-skkni"
}

export interface UpdatePasswordRequest {
  current_password: string
  new_password: string
  new_password_confirmation: string
}

export interface DashboardData {
  course: number
  sold: number
  pending: number
  failed: number
  partnership: Array<{
    full_name: string
    courses_count: number
  }>
  top: Array<{
    title: string
    price: {
      currency_text: string
      current_price_text: string
      master_price: string
      master_price_text: string
    }
    success_transactions: number
  }>
  list: Array<{
    title: string
  }>
  revenue: number
  partner: number
}

export interface CreateEmployeeRequest {
  email: string
  full_name: string
  phone: string
  password: string
  roles: string[]
}

export interface UploadThumbnail {
  thumbnail: File
}

export interface UploadIcon {
  icon: File
}

export interface CMSCourseSectionVideo {
  id: number
  section_id: number
  sequence: number
  title: string
  notes: string
  original_name: string
  original_size: string
  original_mime: string
  media_id: string
  bucket: string
  dir: string
  created_at: string
  updated_at: string
  is_preview: boolean
  encoding: {
    failed: boolean
    percentage: number
    completed: boolean
  }
}

export interface UploadThumbnailCourseRequest {
  courseID: string
  payload: UploadThumbnail
}

export interface UploadThumbnailWebinarRequest {
  webinarID: string
  payload: UploadThumbnail
}

export interface UploadThumbnailCompanyIconRequest {
  careerId: string
  payload: UploadIcon
}

export interface UploadThumbnailSertifikatRequest {
  sertifikatId: string
  payload: UploadThumbnail
}

export interface GeneralDiscountRequest {
  name: string
  type: "REBATE" | "PERCENT"
  discountable_type: "COURSE" | "WEBINAR" | "SERTIFIKASI"
  course_ids?: number[]
  webinar_ids?: number[]
  sertifikasi_ids?: number[]
  value: number
  start_active_at: string
  valid_until: string
}

export interface GeneralReferralRequest {
  name: string
  code: string
  type: "REBATE" | "PERCENT"
  value: number
  is_active: boolean
  course_ids?: number[]
}

export interface UpdateDiscountRequest {
  id: string
  payload: GeneralDiscountRequest
}

export interface UpdateReferralRequest {
  id: string
  payload: GeneralReferralRequest
}

export interface UpdateSertifikatRequest {
  id: string
  payload: GeneralSertifikatRequest
}

export interface UpdateSertifikatUserRequest {
  id: string
  payload: GeneralSertifikatUserRequest
}

export interface CreateVideoRequest {
  video_id: string
  is_preview: boolean
  title: string
  sequence: number
  section_id: number
  notes: string
}

export interface UpdateVideoRequest {
  videoID: string
  payload: CreateVideoRequest
}

export interface Video {
  id: number
  section_id: number
  sequence: number
  title: string
  notes: string
  original_name: string
  original_size: string
  original_mime: string
  media_id: null
  bucket: null
  dir: null
  created_at: string
  updated_at: string
  is_preview: true
}

export interface Transaction {
  id: number
  trx_id: string
  amount: number
  user_id: string
  status: string
  snap_token: string
  external_status: string
  buyer: User
  paid_at: string
  created_at: string
  updated_at: string
  transaction_detail: TransactionDetail
  user: User
}

export interface TransactionDetail {
  id: number
  transactionable_type: string
  original_price: number
  price: number
  transactionable?: Transactionable
}

export interface Transactionable {
  id: number
  title: string
  lecturer?: string
  thumbnail?: string
  slug: string
  description?: string
  price?: ProductPrice
  is_active?: boolean
  buyable?: boolean
  publishable?: boolean
  categories?: ProductCategory[]
  created_at?: string
  updated_at?: string
}

export type CreateVideoResponse = Omit<
  GeneralApiResponse<Video>,
  "links" | "meta"
>
export interface GeneralCareerCategoryRequest {
  name: string
}

export interface GeneralCourseCategoryQuestionRequest {
  course_id: number
  name: string
  type: number
  section_id?: number
}
export interface GeneralQuestionRequest {
  exam_id: number
  content: string
}
export interface GeneralChoiceQuestionRequest {
  question_id: number
  name: string
  is_true?: boolean
}

export interface GeneralGiveawayRequest {
  user_id: number
  course_id: string
}

export interface GeneralSertifikatRequest {
  name: string
  description: string
  price: number
}

export interface GeneralSertifikatUserRequest {
  status: string
  image?: File
}

export interface GeneralSertifikatProgramRequest {
  sertifikat_id: number
  name: string
  type: string
  code: string
}

export interface GeneralSlideRequest {
  ordering_number: string
  image_desktop: File
  image_mobile: File
  link?: string
}

export interface GeneralPartnershipRequest {
  full_name: string
  email: string
  password: string
}

export interface UpdatePartnershipRequest {
  id: string
  payload: GeneralPartnershipRequest
}

export interface UpdatePartnershipPasswordRequest {
  id: string;
  payload: {
    password: string;
  };
}


export interface UpdateCareerCategoryRequest {
  id: string
  payload: GeneralCareerCategoryRequest
}

export interface GeneralCourseCategoryRequest {
  name: string
  description: string
}

export interface UpdateCourseCategoryRequest {
  id: string
  payload: GeneralCourseCategoryRequest
}

export interface GeneralCareerRequest {
  title: string
  description: string
  page: string
  location: string
  working_type: string
  company: string
  expiration?: string
  category_ids?: number[]
  status_pekerjaan: string
}

export interface UpdateCareerRequest {
  id: string
  payload: GeneralCareerRequest
}

export type GeneralTransactionsResponse = GeneralApiResponse<Transaction[]>

export type CourseVideoSectionResponse = Omit<
  GeneralApiResponse<CMSCourseSectionVideo[]>,
  "links" | "meta"
>

export type GeneralDiscountResponse = Omit<
  GeneralApiResponse<Discount>,
  "links" | "meta"
>

export type GeneralReferralResponse = Omit<
  GeneralApiResponse<Referral>,
  "links" | "meta"
>

export type GeneralCareerCategoryResponse = Omit<
  GeneralApiResponse<CareerCategory>,
  "links" | "meta"
>

export type GeneralCourseCategoryQuestionResponse = Omit<
  GeneralApiResponse<CourseCategoryQuestion>,
  "links" | "meta"
>
export type GeneralQuestionResponse = Omit<
  GeneralApiResponse<CourseQuestionDetail>,
  "links" | "meta"
>
export type GeneralChoiceQuestionResponse = Omit<
  GeneralApiResponse<CourseChoice>,
  "links" | "meta"
>

export type GeneralGiveawayResponse = Omit<
  GeneralApiResponse<UserCourse>,
  "links" | "meta"
>

export type GeneralSertifikatResponse = Omit<
  GeneralApiResponse<Sertifikat>,
  "links" | "meta"
>

export type GeneralSertifikatUserResponse = Omit<
  GeneralApiResponse<SertifikatUser>,
  "links" | "meta"
>

export type GeneralPartnershipResponse = Omit<
  GeneralApiResponse<Partnership>,
  "links" | "meta"
>

export type GeneralCareerCategoriesResponse = Omit<
  GeneralApiResponse<CareerCategory[]>,
  "links" | "meta"
>

export type GeneralCourseResponse = Omit<
  GeneralApiResponse<Course[]>,
  "links" | "meta"
>

export type GeneralCareerResponse = Omit<
  GeneralApiResponse<Career>,
  "links" | "meta"
>

export type GeneralSlideResponse = Omit<
  GeneralApiResponse<Slide>,
  "links" | "meta"
>

export type GeneralSertifikasiSkkniResponse = Omit<
  GeneralApiResponse<SertifikatSkkni>,
  "links" | "meta"
>

export type GeneralSertifikatProgramResponse = Omit<
  GeneralApiResponse<SertifikatProgram>,
  "links" | "meta"
>

export type GeneralWebinarResponse = Omit<
  GeneralApiResponse<Webinar>,
  "links" | "meta"
>

export type GeneralCourseCategoryResponse = Omit<
  GeneralApiResponse<CourseCategory>,
  "links" | "meta"
>

export type DetailDiscountResponse = Omit<
  GeneralApiResponse<Discount>,
  "links" | "meta"
>
export type UpdatePasswordResponse = Omit<
  GeneralApiResponse<null>,
  "links" | "meta" | "data"
>

export type CreateEmployeeResponse = Omit<
  GeneralApiResponse<EmployeeInfo>,
  "links" | "meta"
>

export type UploadThumbnailResponse = Omit<
  GeneralApiResponse<{ thumbnail: string }>,
  "links" | "meta"
>

export type UploadIconResponse = Omit<
  GeneralApiResponse<{ icon: string }>,
  "links" | "meta"
>

export type UserDetailResponse = GeneralApiResponse<User>

export type UsersResponse = GeneralApiResponse<User[]>
export type PartnershipResponse = GeneralApiResponse<Partnership[]>

export type DiscountCourseResponse = GeneralApiResponse<
  Omit<Discount[], "webinars">
>

export type DiscountSertifikasiResponse = GeneralApiResponse<
  Omit<Discount[], "certifications">
>

export type ReferralDetailResponse = GeneralApiResponse<Referral>
export type TransactionDetailResponse = GeneralApiResponse<Transaction>

export type PublishCourseResponse = Omit<
  GeneralApiResponse<Course>,
  "links" | "meta"
>

export type DeactivateCourseResponse = Omit<
  GeneralApiResponse<Course>,
  "links" | "meta"
>

export type DeactivateDiscountResponse = Omit<
  GeneralApiResponse<Discount>,
  "links" | "meta"
>

export type DisableReferralResponse = Omit<
  GeneralApiResponse<Referral>,
  "links" | "meta"
>

export type DiscountWebinarResponse = GeneralApiResponse<
  Omit<Discount[], "courses">
>

export type DeleteSectionResponse = Omit<
  GeneralApiResponse<null>,
  "links" | "meta" | "data"
>

export type DeleteCourseCategoryResponse = Omit<
  GeneralApiResponse<null>,
  "links" | "meta" | "data"
>

export type DeleteCourseCategoryQuestionResponse = Omit<
  GeneralApiResponse<CourseCategoryQuestion>,
  "links" | "meta" | "data"
>
export type DeleteCourseQuestionDetailResponse = Omit<
  GeneralApiResponse<CourseQuestionDetail>,
  "links" | "meta" | "data"
>
export type DeleteChoiceQuestionResponse = Omit<
  GeneralApiResponse<CourseChoice>,
  "links" | "meta" | "data"
>

export type VideoDetailResponse = Omit<
  GeneralApiResponse<Video>,
  "links" | "meta"
>

export type GetVideoMaxSequenceResponse = Omit<
  GeneralApiResponse<number>,
  "links" | "meta"
>
export type GetCourseQuestionDetailResponse = Omit<
  GeneralApiResponse<number>,
  "links" | "meta"
>
export type getChoiceQuestionResponse = Omit<
  GeneralApiResponse<number>,
  "links" | "meta"
>

export type DeleteVideoResponse = Omit<
  GeneralApiResponse<null>,
  "links" | "meta" | "data"
>

export type GetDiscountCourseParams = GeneralQueryParams<
  UseQueryOptions<DiscountCourseResponse>
>

export type GetDiscountSertifikasiParams = GeneralQueryParams<
  UseQueryOptions<DiscountSertifikasiResponse>
>

export type GetSertifikasiSkkniParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<SertifikatSkkni[]>>
>

export type getTransactionsParams = GeneralTransactionQueryParams<
  UseQueryOptions<GeneralApiResponse<Transaction[]>>
>

export type GetDiscountWebinarParams = GeneralQueryParams<
  UseQueryOptions<DiscountWebinarResponse>
>

export type GetDiscountDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<DetailDiscountResponse>>,
  "qParams"
> & { id: string }

export type GetCareerDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<GeneralCareerResponse>>,
  "qParams"
> & { id: string }

export type GetSertifikatDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<GeneralSertifikatResponse>>,
  "qParams"
> & { id: string }

export type GetPartnershipsParams = Omit<
  GeneralQueryParams<UseQueryOptions<GeneralPartnershipResponse>>,
  "qParams"
> & { id: string }

export type GetWebinarDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<GeneralCareerResponse>>,
  "qParams"
> & { id: string }

export type GetCourseCategoryParams = Omit<
  GeneralQueryParams<UseQueryOptions<GeneralCourseCategoryResponse>>,
  "qParams"
> & { id: string }

export type GetReferralDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<GeneralReferralResponse>>,
  "qParams"
> & { id: string }

export type GetCoursesParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<Course[]>>
>

export type GetWebinarsParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<Webinar[]>>
>

export type GetUsersParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<User[]>>
>

export type GetPartnershipParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<Partnership[]>>
>

export type GetCareerCategoriesParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<CareerCategory[]>>
>

export type GetCareersParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<Career[]>>
>

export type GetCourseCategoryQuestionParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<CourseCategoryQuestion[]>>
>

export type GetCourseSectionParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<CourseSection[]>>
>

export type GetCourseParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<Course[]>>
>

export type GetUserCourseParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<UserCourse[]>>
>

export type GetWebinarPesertaParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<WebinarPeserta[]>>
>

export type GetSertifikatParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<Sertifikat[]>>
>

export type GetSertifikatUserParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<SertifikatUser[]>>
>

export type GetSertifikatProgramParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<SertifikatProgram[]>>
>

export type GetSlideParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<Slide[]>>
>

export type GetCareerUsersParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<CareerUsers[]>>
>

export type GetCourseVideoSectionParams = Omit<
  GeneralQueryParams<UseQueryOptions<CourseVideoSectionResponse>>,
  "qParams"
> & { sectionID: string }

export type GetVideoDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<VideoDetailResponse>>,
  "qParams"
> & { videoID: string }

export type GetVideoMaxSequenceParams = Omit<
  GeneralQueryParams<UseQueryOptions<GetVideoMaxSequenceResponse>>,
  "qParams"
> & { sectionID: string }

export type GetQuestionDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<GetCourseQuestionDetailResponse>>,
  "qParams"
> & { exam_id: string }

export type GetChoiceQuestionParams = Omit<
  GeneralQueryParams<UseQueryOptions<getChoiceQuestionResponse>>,
  "qParams"
> & { question_id: string }

export interface Discount {
  id: number
  name: string
  type: DiscountType
  value: number
  courses: Course[]
  webinars: Webinar[]
  certifications: Sertifikat[]
  is_active: boolean
  start_active_at: string
  valid_until: string
  created_at: string
  updated_at: string
}

export type DiscountType = "PERCENT" | "REBATE"

interface EmployeeInfo {
  id: number
  email: string
  full_name: string
  phone: string
  created_at: string
  updated_at: string
  roles: Roles[]
}

interface Roles {
  id: number
  name: string
  guard_name: string
  created_at: string
  updated_at: string
  pivot: {
    model_id: number
    role_id: number
    model_type: string
  }
}

export interface User {
  id: number
  phone: string
  email: string
  first_name: string
  middle_name: string
  last_name: string
  created_at: string
  purchased_webinars: Webinar[]
  purchased_courses: Course[]
}

export interface Partnership {
  id: number
  email: string
  email_verified_at?: string
  full_name: string
  password: string
  created_at: string
  updated_at: string
  deleted_at?: string
}

export interface CareerCategory {
  id: number
  name: string
  slug: string
  created_at: string
  updated_at: string
}

export interface Career {
  id: number
  title: string
  slug: string
  is_active: boolean
  description: string
  page: string
  location: string
  working_type: string
  publishable: boolean
  company: string
  expiration: string
  created_at: string
  updated_at: string
  categories: CareerCategory[]
  company_icon: string
  career_users: string
  status_pekerjaan?: string
}

export interface CourseCategoryQuestion {
  id: number
  name: string
  course: Course
  type: string
  section?: {
    title: string
  }
}
export interface CourseQuestionDetail {
  id: number
  exam_id: number
  content: Course
  exam: CourseSectionQuiz
  choices: CourseChoice[]
}

export interface UserCourse {
  user: User
  course: Course
}

export interface WebinarPeserta {
  id: number
  price: number
  no_invoice: string
  transaction: Transaction
  webinars: Webinar
}

export interface Sertifikat {
  id: number
  name: string
  description: string
  price: ProductPrice
  thumbnail?: string
  created_at: string
  updated_at: string
}

export interface SertifikatProgram {
  id: number
  name: string
  code: string
  type: number
  created_at: string
  updated_at: string
}

export interface SertifikatUser {
  id: number
  sertifikat: {
    name: string
  }
  name: string
  email: string
  phone: string
  program: string
  status: string
  created_at: string
  updated_at: string
}

export interface Slide {
  id: number
  ordering_number: number
  link: string
  image_desktop: string
  image_mobile: string
  created_at: string
  updated_at?: string
}

export interface SertifikatSkkni {
  id: number
  name: string
  thumbnail: string
  created_at: string
  updated_at?: string
}

export interface CareerUsers {
  id: number
  name: string
  email: string
  address: string
  cv: string
  portfolio: string
}

export interface Referral {
  id: number
  name: string
  code: string
  type: "REBATE" | "PERCENT"
  value: number
  is_active: boolean
  created_at: string
  updated_at: string
  courses: Course[]
  transaction_count?: number
}

export interface CourseCategory {
  id: number
  name: string
  slug: string
  description: string
  created_at: string
  updated_at: string
}
