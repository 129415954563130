import { requestUser } from "utils/axiosInstance"
import type { GetScoreExamResponse } from "../../types"

const getCourseSectionQuiz = async (exam_id: number, course_id: number) => {
    const res = await requestUser.get<GetScoreExamResponse>(
        `/user/course-choice/detail?exam_id=${exam_id}&course_id=${course_id}`
    )
    return res
}

export default getCourseSectionQuiz
