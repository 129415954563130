import { Link, useNavigate } from "react-router-dom"
import Button from "components/ui/button/Button"
import Typography from "components/ui/typography"
import { useUserInfoStore } from "utils/store/user-info"
import { RiCalendarTodoLine, RiPencilRuler2Line } from "react-icons/ri"
import { BsClock, BsPeople } from "react-icons/bs"
import { FiMapPin } from "react-icons/fi"
import { CgNotes } from "react-icons/cg"
import { HiOutlineChatAlt2 } from "react-icons/hi"
import { FaRegFilePowerpoint, FaUserGraduate } from "react-icons/fa"
import { Badge } from "flowbite-react"
import noImage from "assets/images/no-image.jpg"

interface ContentWorkshopProps {
  id: string
  slug: string
  currency: string
  masterPrice: string
  currentPrice: string
  startDate?: string
  endDate?: string
  speakers: string[]
  quota?: number
  thumbnail?: string
  buyable: boolean
  offline?: boolean
  online?: boolean
  location?: string
}

const ContentWorkshop = ({
  id,
  slug,
  currency,
  masterPrice,
  startDate,
  endDate,
  currentPrice,
  speakers,
  quota,
  thumbnail,
  buyable,
  offline,
  online,
  location,
}: ContentWorkshopProps) => {
  const isLoggedIn = useUserInfoStore((state) => state.isLoggedIn)
  const navigate = useNavigate()

  const handleCheckout = () => {
    navigate(`/checkout/webinar/${slug}`)
  }

  let dataLokasi = ""
  if (online && !offline) {
    dataLokasi = "Gmeet"
  } else if (!online && offline) {
    dataLokasi = `${location}`
  }
  const lokasi = dataLokasi

  const isDiscount = masterPrice !== currentPrice

  return (
    <div className="flex flex-col">
        <img src={thumbnail ?? noImage} alt={slug} className="w-full rounded-t-md" />
      <div className="flex flex-col p-4 border gap-4 w-full border-b-2">
        <div className="flex flex-col gap-2">
          {currentPrice === '0' ? (
            <Badge className="px-2 w-12 text-center" color="success">Free</Badge>
          ) : (
            <>
              <Typography
                variant="subheading4"
                className="font-semibold"
              >
                {currency}
                {currentPrice}
              </Typography>
              {isDiscount && (
                <Typography className="font-normal text-gray-400 line-through decoration-2">
                  {currency}
                  {masterPrice}
                </Typography>
              )}
            </>
          )}
        </div>
        <Button
          className="w-full font-normal"
          as={isLoggedIn ? "button" : Link}
          disabled={isLoggedIn && !buyable}
          {...(isLoggedIn
            ? {
              onClick: handleCheckout,
            }
            : {
              to: "/register",
            })}
        >
          Daftar Workshop
        </Button>
      </div>
      <div className="grid p-4 border gap-2 w-full border-b-2 items-center">
        <Typography
          variant="text2"
          className="font-semibold text-primary"
        >
          Mentor
        </Typography>
        <div className="flex flex-wrap gap-2">
          {speakers.map((speak, index) => (
            <Typography
              key={index}
              className="font-normal flex gap-2 items-center">
              <FaUserGraduate className="text-primary w-4 h-4" />
              {speak}
            </Typography>
          ))}
        </div>
      </div>
      <div className="flex flex-col p-4 border gap-2 w-full border-b-2">
        <Typography
          variant="text2"
          className="font-semibold"
        >
          Informasi Workshop
        </Typography>
        <div className="grid gap-2">
          <div className="flex gap-4 items-center">
            <RiCalendarTodoLine className="text-primary w-6 h-6" />
            <div className="grid">
              <Typography
                className="font-normal text-sm text-gray-300 ">
                Tanggal
              </Typography>
              <Typography
                className="font-semibold text-sm text-gray-600 ">
                {`${startDate}-${endDate}`}
              </Typography>
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <BsClock className="text-primary w-6 h-6" />
            <div className="grid">
              <Typography
                className="font-normal text-sm text-gray-300 ">
                Waktu
              </Typography>
              <Typography
                className="font-semibold text-sm text-gray-600 ">
                15:30 WIB - Selesai
              </Typography>
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <FiMapPin className="text-primary w-6 h-6" />
            <div className="grid">
              <Typography
                className="font-normal text-sm text-gray-300 ">
                Lokasi
              </Typography>
              <Typography
                className="font-semibold text-sm text-gray-600 ">
                {lokasi}
              </Typography>
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <BsPeople className="text-primary w-6 h-6" />
            <div className="grid">
              <Typography
                className="font-normal text-sm text-gray-300 ">
                Quota
              </Typography>
              <Typography
                className="font-semibold text-sm text-gray-600 ">
                {quota}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-4 border gap-4 w-full rounded-b-md">
        <Typography
          variant="text2"
          className="font-semibold"
        >
          include
        </Typography>
        <div className="grid gap-5 ">
          <div className="flex gap-4 items-center">
            <FaRegFilePowerpoint className="text-primary w-6 h-6" />
            <Typography
              className="font-semibold text-sm">
              Slide Materi
            </Typography>
          </div>
          <div className="flex gap-4 items-center">
            <CgNotes className="text-primary w-6 h-6" />
            <Typography
              className="font-semibold text-sm">
              Sertifikasi
            </Typography>
          </div>
          <div className="flex gap-4 items-center">
            <HiOutlineChatAlt2 className="text-primary w-6 h-6" />
            <Typography
              className="font-semibold text-sm">
              Group Diskusi
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentWorkshop
