import { UserIcon } from "@heroicons/react/20/solid"
import { Tooltip } from "flowbite-react"

import Typography from "components/ui/typography"
import { ProductCategory } from "utils/types"

interface TitleProps {
  title: string
  categories: ProductCategory[]
  startDate: string
  endDate: string
}

const WorkshopTitle = ({
  title,
  categories,
  startDate,
  endDate
}: TitleProps) => {

  const workshopStatus = () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999);
    if (now < start) {
      const diffDays = Math.round((start.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
      return `Upcoming`;
    } else if (now >= start && now <= end) {
      return "Sedang berlangsung";
    } else {
      return "Workshop telah berakhir";
    }
  };

  return (
    <div className="lg:w-2/3">
      <Typography
        variant="subheading2"
        className="mb-2"
      >
        {title}
      </Typography>
      <div className="flex flex-wrap gap-2 w-full">
        <Typography
          variant="text3"
          className="font-medium p-2 bg-red-600 text-white rounded-md"
        >
          {workshopStatus()}
        </Typography>
        {categories.map((category) => (
          <Typography
            key={category.id}
            variant="text3"
            className="font-medium p-2 bg-gray-200 text-gray-600 rounded-md"
          >
            {category.name}
          </Typography>
        ))}
      </div>
    </div>
  )
}

export default WorkshopTitle
