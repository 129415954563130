import { useQuery } from "@tanstack/react-query"

import { getUserCourse } from "../functions"
import { CmsQueryKey, GetUserCourseParams } from "../../types"

const useGetUserCourseList = (params?: GetUserCourseParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.UserCourse, params?.qParams ?? ""],
    queryFn: () => getUserCourse(params?.qParams),
    ...params?.options,
  })
}

export default useGetUserCourseList
