import { requestCMS } from "utils/axiosInstance"
import type { CourseSection, GeneralApiResponse, QueryParams } from "utils/types"

const getCourseSection = async (qParams?: QueryParams) => {
    const res = await requestCMS.get<GeneralApiResponse<CourseSection[]>>(
        "/employee/course/section",
        qParams
    )
    return res
}

export default getCourseSection
