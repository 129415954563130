import { requestUser } from "utils/axiosInstance"
import { PublicSubmitQuestionResponse, QuestionRequest } from "../../types"

const submitQuestion = async (payload: QuestionRequest) => {
    const res = await requestUser.post<PublicSubmitQuestionResponse>(
        "/user/course/question/answer",
        payload
    )
    return res
}

export default submitQuestion
