import { useQuery } from "@tanstack/react-query"

import {  getChoiceQuestion } from "../functions"
import { CmsQueryKey, GetChoiceQuestionParams } from "../../types"

const useGetChoiceQuestion = (params?: GetChoiceQuestionParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.choiceQuestion, params?.question_id ?? ""],
        queryFn: () => getChoiceQuestion(params?.question_id ?? ""),
        ...params?.options,
    })
}

export default useGetChoiceQuestion
