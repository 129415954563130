import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline"
import Typography from "components/ui/typography"
import clsxm from "utils/clsxm"

interface ExamButtonProps {
    title?: string
    onClick?: any
    selected?: boolean // Add selected prop
}

const ExamButton = ({
    title,
    onClick,
    selected // Destructure selected prop
}: ExamButtonProps) => {
    return (
        <button
            className={clsxm(
                "flex gap-2 items-center",
                "w-full",
                "p-4 text-lg",
                "bg-white",
                "hover:bg-primary/5 ",
                "border-b",
                // Add conditional styling for selected state
                selected && " text-primary bg-primary/5 "
            )}
            onClick={onClick}
        >
            <ClipboardDocumentCheckIcon
                width={25}
                height={25}
                className=""
            />
            <Typography className="w-full text-start">{title}</Typography>
        </button>
    )
}

export default ExamButton
