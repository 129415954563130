import { useQuery } from "@tanstack/react-query"

import { getCourseSection } from "../functions"
import { CmsQueryKey, GetCourseSectionParams } from "../../types"

const useGetCourseSection = (params?: GetCourseSectionParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.CourseSection, params?.qParams ?? ""],
        queryFn: () => getCourseSection(params?.qParams),
        ...params?.options,
    })
}

export default useGetCourseSection
