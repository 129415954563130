import {
  PlayCircleIcon,
  PlayIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline"

import Typography from "components/ui/typography"
import { FaPauseCircle } from "react-icons/fa"
import clsxm from "utils/clsxm"

interface VideoButtonProps {
  selected?: boolean
  title: string
  disabled?: boolean
  className?: string
  onClick: () => void
}

const VideoButton = ({
  selected,
  title,
  disabled,
  className,
  onClick,
}: VideoButtonProps) => {
  return (
    <button
      {...(!disabled && { onClick })}
      className={clsxm(
        "flex gap-2 items-center",
        "w-full md:w-[80%]",
        "p-4 text-lg",
        "bg-white",
        "hover:bg-primary/5 ",
        "border-b",
        [
          selected &&
          " text-primary bg-primary/5 ",
        ],
        [disabled && "cursor-default brightness-75"],
        className
      )}
    >
      {!disabled ? (
        <>
          {selected ? (
            <FaPauseCircle
              className="text-primary w-[25px] h-[25px]"
            />
          ) : (
            <PlayCircleIcon
              width={25}
              height={25}
              className=""
            />
          )}
        </>
      ) : (
        <LockClosedIcon
          width={40}
          height={40}
          className="text-white"
        />
      )}
      <Typography className="w-full text-start">{title}</Typography>
    </button>
  )
}

export default VideoButton
