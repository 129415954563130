import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey, GetCourseParams } from "../../types"
import getCourseAll from "../functions/getCourseAll"

const useGetCourseAll = (params?: GetCourseParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.Courses, params?.qParams ?? ""],
        queryFn: () => getCourseAll(params?.qParams),
        ...params?.options,
    })
}

export default useGetCourseAll
