import { requestCMS } from "utils/axiosInstance"
import { DeleteChoiceQuestionResponse } from "utils/api/cms/types"

const deleteChoiceQuestion = async (choiceID: string) => {
  const res = await requestCMS.delete<DeleteChoiceQuestionResponse>(
    `/employee/course/choice/${choiceID}`
  )
  return res
}

export default deleteChoiceQuestion
