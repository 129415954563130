import { useQuery } from "@tanstack/react-query"

import { getScoreQuestion } from "../functions"
import { GetScoreExamParams, UserQueryKey } from "../../types"

const useGetScoreQuestion = (params?: GetScoreExamParams) => {
  return useQuery({
    queryKey: [UserQueryKey.ScoreQuestion, params?.exam_id ?? 0 , params?.course_id ?? 0],
    queryFn: () => getScoreQuestion(params?.exam_id ?? 0 , params?.course_id ?? 0),
    ...params?.options,
  })
}

export default useGetScoreQuestion
