import { DocumentPlusIcon } from "@heroicons/react/24/solid"
import { Link } from "react-router-dom"

import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"
import Button from "components/ui/button/Button"
import CareerList from "components/cms/CareerList"

const CareerManagement = () => {
  const segments = getCMSSegments()

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <div className="inline-flex items-center">
        <Typography
          variant="subheading4"
          as="h1"
          className="text-primary mt-4"
        >
          Pengaturan Karir
        </Typography>

      </div>
      <CareerList />
    </EmployeeDashboardWrapper>
  )
}

export default CareerManagement
