import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  PaginationState,
  OnChangeFn,
} from "@tanstack/react-table"
import DarkPagination from "../pagination/DarkPagination"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import { Table } from "flowbite-react"
import clsxm from "utils/clsxm"

interface ReactTableProps {
  columns: ColumnDef<any, any>[]
  data: any[]
  pagination?: PaginationState
  pageCount?: number
  TextWrap?:boolean
  onPaginationChange?: OnChangeFn<PaginationState>
}

const ReactTable = ({
  columns,
  data,
  TextWrap,
  pagination,
  pageCount,
  onPaginationChange,
}: ReactTableProps) => {
  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: { pagination },
    manualPagination: typeof pagination !== "undefined",
    onPaginationChange,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <div className="max-h-[450px] relative overflow-x-auto sm:rounded-lg w-full">
        <Table className="relative">
          {table.getHeaderGroups().map((headerG) => (
            <Table.Head key={headerG.id} className="sticky top-0 z-10">
              {headerG.headers.map((header) => (
                <Table.HeadCell key={header.id} className="py-5">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </Table.HeadCell>
              ))}
            </Table.Head>
          ))}
          <Table.Body className="divide-y">
            {table.getRowModel().rows.map((row) => (
              <Table.Row key={row.id} className="bg-white dark:border-gray-700 dark:bg-gray-800 w-4">
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell key={cell.id} className={clsxm(
                    `whitespace-nowrap font-medium text-gray-900 dark:text-white`,
                    [TextWrap && "whitespace-normal"]
                    )}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className="mt-4 ml-auto">
        {pagination && (
          <DarkPagination
            nexLabel={
              <ChevronRightIcon
                width={20}
                height={20}
                className="text-gray-400"
              />
            }
            previousLabel={
              <ChevronLeftIcon
                width={20}
                height={20}
                className="text-gray-400"
              />
            }
            pageCount={table.getPageCount()}
            pageRangeDisplayed={2}
            currentPage={table.getState().pagination.pageIndex}
            initialPage={pagination.pageIndex}
            onPageChange={table.setPageIndex}
          />
        )}
      </div>
    </>
  )
}

export default ReactTable
