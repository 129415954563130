import { requestUser } from "utils/axiosInstance"
import type { GetCourseQuestionResponse } from "../../types"

const getCourseSectionQuiz = async (exam_id: string) => {
  const res = await requestUser.get<GetCourseQuestionResponse>(
    `/user/course-question?exam_id=${exam_id}`
  )
  return res
}

export default getCourseSectionQuiz
