import { useQuery } from "@tanstack/react-query"

import {  getCourseQuestionDetail } from "../functions"
import { CmsQueryKey, GetQuestionDetailParams } from "../../types"

const useGetCourseQuestionDetail = (params?: GetQuestionDetailParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.CourseQuestionDetail, params?.exam_id ?? ""],
        queryFn: () => getCourseQuestionDetail(params?.exam_id ?? ""),
        ...params?.options,
    })
}

export default useGetCourseQuestionDetail
