import { useMutation } from "@tanstack/react-query"

import { DeleteCourseCategoryQuestionResponse } from "../../types"
import { deleteCategoryCourseQuestion } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationDeleteCategoryCourseQuestion = (
    options?: GeneralMutationParams<DeleteCourseCategoryQuestionResponse, string>
) => {
    return useMutation({
        mutationFn: (courseCategoryID) => deleteCategoryCourseQuestion(courseCategoryID),
        ...options,
    })
}

export default useMutationDeleteCategoryCourseQuestion
