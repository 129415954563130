import { requestCMS } from "utils/axiosInstance"
import { GeneralApiResponse, QueryParams } from "utils/types"
import { UserCourse } from "../../types"

const getUserCourse = async (qParams?: QueryParams) => {
    const res = await requestCMS.get<GeneralApiResponse<UserCourse[]>>(
        "/employee/user/course",
        qParams
    )
    return res
}

export default getUserCourse
