import { useMutation } from "@tanstack/react-query"

import { resetScore } from "../functions"
import { GeneralMutationParams } from "utils/types"
import { PublicSubmitResetScoreResponse, ResetScorePayload } from "../../types"

const useMutationResetScore = (
    options?: GeneralMutationParams<PublicSubmitResetScoreResponse, ResetScorePayload>
) => {
    return useMutation({
        mutationFn: (payload) => resetScore(payload),
        ...options,
    })
}

export default useMutationResetScore
