import clsx from "clsx"

interface CardSkeletonprop {
  className?: string
}
const CardSkeleton = ({ className }: CardSkeletonprop) => {
  return (
    <div
      className={clsx(
        "w-[292px] h-[350px]",
        "rounded-lg",
        "shadow",
        "animate-pulse",
        className
      )}
    >
      <div
        className={clsx(
          "w-full h-[60%]",
          "rounded-t-lg",
          "bg-slate-200",
          "mb-4"
        )}
      />
      <div className="flex flex-col gap-1 px-4">
        <div className={clsx("w-1/2 h-4", "rounded-full", "bg-slate-200")} />
        <div
          className={clsx(
            "w-1/4 h-3.5",
            "rounded-full",
            "bg-slate-200",
            "mb-2"
          )}
        />
        <div className="flex items-center gap-2">
          <div className={clsx("w-12 h-12", "rounded-full", "bg-slate-200")} />
          <div className="w-1/2 space-y-2">
            <div className={clsx("w-20 h-4", "rounded-full", "bg-slate-200")} />
            <div className={clsx("w-28 h-3", "rounded-full", "bg-slate-200")} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardSkeleton
