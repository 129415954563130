import { Radio, Label } from "flowbite-react"
import Typography from "components/ui/typography"
import { Section } from "./types"

interface SideFilterProps {
  sections: Section[]
  handleDrawer?:  (value: boolean) => void
}

const SideFilter = ({ sections, handleDrawer }: SideFilterProps) => {
  return (
    <div className="w-64 my-2">
      {sections.map((section, index) => (
        <fieldset
          key={index}
          className="flex flex-col gap-4 max-w-md"
          id="radio"
        >
          <legend className="mb-4 text-lg font-semibold text-tertiary">
            {section.category}
          </legend>
          {section.items.map((item, index) => (
            <div
              key={index}
              className="flex items-center gap-2"
            >
              <Radio
                defaultChecked={index === 0}
                id={item.name}
                onClick={(e) => {
                  e.stopPropagation();
                  if (handleDrawer) handleDrawer(false);
                }}
                className="text-primary cursor-pointer focus:outline-primary"
                value={item.value}
                {...section.register}
              />
              <Label
                className="flex cursor-pointer"
                htmlFor={item.name}
              >
                <Typography
                  variant="text1"
                  className="text-tertiary"
                >
                  {item.name}
                </Typography>
              </Label>
            </div>
          ))}
        </fieldset>
      ))}
    </div>
  )
}

export default SideFilter
