import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import React, { useEffect } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import ButtonWithIcon from "../ui/button/ButtonWithIcon"
import BorderedRadioInput from "../ui/form/BorderedRadioInput"
import NumberInput from "../ui/form/NumberInput"
import TextInput from "../ui/form/TextInput"
import BasicValidationMessage from "../ui/validation/BasicValidationMessage"
import makeAnimated from "react-select/animated"
import clsx from "clsx"
import classes from "../../assets/styles/ui/Input.module.css"
import Select from "react-select"
import moment from "moment"
import ReactDatePicker from "react-datepicker"

export type ReferralForm = {
  name: string;
  code: string;
  value: number;
  is_active: boolean;
  start_active_at: Date | null;
  valid_until: Date | null;
  course_ids: number[];
};
const ReferralManagementForm: React.FC<{
  method: "POST" | "PUT"
  onSubmitHandler: SubmitHandler<ReferralForm>
  categoryOptions: {
    label: string
    value: number | string
  }[]
  serverValidationResponse?: {
    name: string | undefined
    code: string | undefined
    value: number | undefined
    is_active: boolean | undefined
    start_active_at: string | undefined
    valid_until: string | undefined
    course_ids: number[] | undefined
  }
  submitting?: boolean
  initialValue?: ReferralForm
}> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    getValues,
  } = useForm<ReferralForm>({
    mode: "all",
    defaultValues: props.initialValue,
  })

  React.useEffect(() => {
    if (props.serverValidationResponse !== undefined) {
      Object.keys(props.serverValidationResponse as object).forEach((field) => {
        const messageVal: string | undefined =
          props.serverValidationResponse![field]

        if (messageVal === undefined) {
          return
        }

        setError(field as keyof ReferralForm, {
          type: "custom",
          message: messageVal,
        })
      })
    }
  }, [props.serverValidationResponse])

  const payloadDateFormat = "YYYY-MM-DD"
  
  useEffect(() => {
    if (getValues("start_active_at") && getValues("valid_until")) {
      const startActiveAt = moment(getValues("start_active_at"));
      const validUntil = moment(getValues("valid_until"));
      if (validUntil.isBefore(startActiveAt)) {
        setError("valid_until", {
          type: "custom",
          message: "The valid until field must be a date after start active at."
        });
      }
    }
  }, [getValues("start_active_at"), getValues("valid_until")]);

  return (
    <form
      method={props.method}
      onSubmit={handleSubmit(props.onSubmitHandler)}
    >
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        <div className="sm:col-span-2">
          <TextInput
            inputClass="cms-base-input min-w-[42rem]"
            labelClass="cms-base-label"
            isRequired
            label="Nama Referral"
            name="name"
            value=""
            hooks={{
              ...register("name", {
                required: "Nama referral tidak boleh kosong!",
                minLength: {
                  message: "Nama referral minimal 3 karakter!",
                  value: 3,
                },
                maxLength: {
                  message: "Nama referral tidak boleh melebihi 255 karakter!",
                  value: 255,
                },
              }),
            }}
            validationMessage={
              errors.name && (
                <BasicValidationMessage
                  type="error"
                  message={errors.name.message as string}
                  icon={
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                  }
                />
              )
            }
          />
        </div>
        <div className="sm:col-span-2">
          <TextInput
            inputClass="cms-base-input min-w-[42rem]"
            labelClass="cms-base-label"
            isRequired
            label="Kode Referral"
            name="code"
            value=""
            hooks={{
              ...register("code", {
                required: "Kode referral tidak boleh kosong!",
                minLength: {
                  message: "Kode referral minimal 3 karakter!",
                  value: 3,
                },
                maxLength: {
                  message: "Kode referral tidak boleh melebihi 255 karakter!",
                  value: 255,
                },
              }),
            }}
            validationMessage={
              errors.code && (
                <BasicValidationMessage
                  type="error"
                  message={errors.code.message as string}
                  icon={
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                  }
                />
              )
            }
          />
        </div>
        <div className="sm:col-span-2">
          <NumberInput
            min={1}
            inputClass="cms-base-input min-w-[42rem]"
            labelClass="cms-base-label"
            isRequired
            label="Potongan Harga Referral"
            name="value"
            value={""}
            hooks={{
              ...register("value", {
                required: "Potongan harga tidak boleh kosong!",
                min: {
                  message: "Potongan harga tidak boleh 0!",
                  value: 1,
                },
              }),
            }}
            validationMessage={
              errors.value && (
                <BasicValidationMessage
                  type="error"
                  message={errors.value.message as string}
                  icon={
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                  }
                />
              )
            }
          />
        </div>
        <div className="sm:col-span-2 max-w-[42rem]">
          <Controller
            control={control}
            name="is_active"
            render={({ field }) => (
              <BorderedRadioInput
                isRequired
                optionsWrapper="grid md:grid-cols-2 gap-6"
                value={
                  getValues("is_active") === undefined
                    ? ""
                    : getValues("is_active")
                      ? "ACTIVE"
                      : "INACTIVE"
                }
                inputClass=""
                label="Status"
                name="webinar_execution"
                labelClass="cms-base-label"
                options={[
                  {
                    label: "Active",
                    value: "ACTIVE",
                  },
                  {
                    label: "Inactive",
                    value: "INACTIVE",
                  },
                ]}
                changeHandler={(event) => {
                  field.onChange(event.target.value === "ACTIVE")
                }}
              />
            )}
          />
        </div>
        <div className="sm:col-span-2 max-w-[42rem]">
          <label className={clsx("cms-base-label", classes.required)}>
            Tanggal Pelaksanaan Webinar
          </label>
          <div className="flex items-center w-full gap-2">
            <div className="w-full">
              <Controller
                control={control}
                name="start_active_at"
                render={({ field }) => (
                  <ReactDatePicker
                    className="w-full rounded-lg"
                    selected={field.value}
                    selectsStart
                    startDate={field.value}
                    endDate={getValues("valid_until")}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    onChange={(update) => {
                      field.onChange(update);
                    }}
                    withPortal
                    showTimeSelect
                  />
                )}
              />
            </div>
            <span className="text-white">-</span>
            <div className="w-full">
              <Controller
                control={control}
                name="valid_until"
                render={({ field }) => (
                  <ReactDatePicker
                    className="w-full rounded-lg"
                    selected={field.value}
                    selectsEnd
                    startDate={getValues("start_active_at")}
                    endDate={field.value}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    onChange={(update) => {
                      field.onChange(update);
                    }}
                    withPortal
                    showTimeSelect
                  />
                )}
              />
            </div>
          </div>
          {errors.start_active_at && errors.start_active_at.message && (
            <BasicValidationMessage
              type="error"
              message={errors.start_active_at.message}
              icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-800" />}
            />
          )}
          {errors.valid_until && errors.valid_until.message && (
            <BasicValidationMessage
              type="error"
              message={errors.valid_until.message}
              icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-800" />}
            />
          )}
        </div>
        <div className="sm:col-span-2">
          <label className={clsx("cms-base-label", classes.required)}>
            Pilih Modul Pembelajaran
          </label>
          <Controller
            control={control}
            defaultValue={[]}
            name="course_ids"
            rules={{
              required: "Pilihan modul belajar tidak boleh kosong!",
            }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                name={name}
                isMulti
                ref={ref}
                closeMenuOnSelect={false}
                components={makeAnimated()}
                classNames={{
                  container: () =>
                    clsx("w-[42rem]", errors.course_ids && "mb-2"),
                  control: () => "p-2.5 rounded-lg",
                }}
                options={props.categoryOptions}
                value={props.categoryOptions.filter((c) =>
                  value.includes(c.value as number)
                )}
                onChange={(val) =>
                  onChange(val.map((c) => c.value) as number[])
                }
                onBlur={onBlur}
              />
            )}
          />
          {errors.course_ids && (
            <BasicValidationMessage
              type="error"
              message={errors.course_ids?.message as string}
              icon={
                <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
              }
            />
          )}
        </div>
      </div>
      {!props.submitting && (
        <ButtonWithIcon
          action={undefined}
          type="submit"
          class="mt-4 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        >
          <BookmarkIcon className="h-6 w-6 text-white" />
          <span>{props.method === "POST" ? "Tambahkan" : "Update"}</span>
        </ButtonWithIcon>
      )}
      {props.submitting && (
        <ButtonWithIcon
          action={undefined}
          type="button"
          class="mt-4 border-2 border-transparent bg-primary text-white space-x-2 cursor-not-allowed"
          disabled
        >
          <svg
            aria-hidden="true"
            className="inline w-6 h-6 mr-2 text-gray-400 animate-spin fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span>Menyimpan...</span>
        </ButtonWithIcon>
      )}
    </form>
  )
}

export default ReferralManagementForm
