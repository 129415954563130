import { requestCMS } from "utils/axiosInstance"

import type {
    GeneralGiveawayRequest,
    GeneralGiveawayResponse,
} from "../../types"

const giveaway = async (payload: GeneralGiveawayRequest) => {
    const res = await requestCMS.post<GeneralGiveawayResponse>(
        "/employee/course/giveaway",
        payload
    )
    return res
}

export default giveaway
