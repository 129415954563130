import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"
import QuestionList from "components/cms/Course/question/QuestionList"

const CourseQuestionManagements = () => {
    const segments = getCMSSegments()

    return (
        <EmployeeDashboardWrapper>
            <DefBreadcrumb
                segments={segments}
                prefix="cms"
            />
            <div className="inline-flex items-center">
                <Typography
                    variant="subheading4"
                    as="h1"
                    className="text-primary mt-4"
                >
                    Tambahkan Pertanyaan
                </Typography>

            </div>
            <QuestionList />
        </EmployeeDashboardWrapper>
    )
}

export default CourseQuestionManagements
