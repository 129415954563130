import React, { useState, useEffect } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import { useMutationResetScore, useMutationSubmitQuiz } from 'utils/api/user/mutations/hooks';
import { useGetCourseQuestion, useGetScoreQuestion } from 'utils/api/user/queries/hooks';
import { Card } from 'flowbite-react';
import Button from 'components/ui/button/Button';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';

interface Choice {
    choice_id: string;
    course_id: number;
}

const QuestionCourse = (props) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userChoices, setUserChoices] = useState<Choice[]>([]);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [timer, setTimer] = useState(0);
    const [timerOn, setTimerOn] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [quizStarted, setQuizStarted] = useState(false);
    const [progress, setProgress] = useState(0); // State untuk progres
    const { data: dataQuestion, isLoading: isLoadingQuestion } = useGetCourseQuestion({
        exam_id: props.selected
    });
    const course_id = dataQuestion?.data?.[currentQuestionIndex]?.exam?.course_id || 0;
    const { mutate, isLoading: isLoadingSubmitQuiz } = useMutationSubmitQuiz();

    const { mutate: mutateResetScore, isLoading: isLoadingResetScore } = useMutationResetScore();

    const { data: dataScoreQuiz, isLoading: isLoadingScoreQuiz, refetch: refetchScoreQuiz } = useGetScoreQuestion({
        exam_id: props.selected,
        course_id: course_id,
    });

    useEffect(() => {
        setTotalQuestions(dataQuestion?.data?.length || 0);
    }, [dataQuestion]);

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (timerOn) {
            interval = setInterval(() => {
                setTimer((prev) => prev + 1);
            }, 1000);
        } else if (interval) {
            clearInterval(interval);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [timerOn]);

    useEffect(() => {
        endQuiz();
    }, [props.selected]);

    useEffect(() => {
        // Menghitung progres berdasarkan skor saat ini
        if (dataScoreQuiz && dataScoreQuiz.data.question !== 0) {
            const progressPercentage = (dataScoreQuiz.data.score / dataScoreQuiz.data.question) * 100;
            setProgress(progressPercentage);
        }
    }, [dataScoreQuiz]);

    const startQuiz = () => {
        setQuizStarted(true);
        setTimer(0);
        setTimerOn(true);
        setUserChoices([]);
        setCurrentQuestionIndex(0);
    };

    const endQuiz = () => {
        setTimerOn(false);
        setElapsedTime(timer);
        setQuizStarted(false);
        setTimeout(async () => {
            await refetchScoreQuiz();
        }, 500);
    };

    const handleNextQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    };

    const handlePrevQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    };

    const handleAnswerChange = (event) => {
        const choice_id = event.target.value;
        const updatedUserChoices = [...userChoices];
        updatedUserChoices[currentQuestionIndex] = { choice_id, course_id };
        setUserChoices(updatedUserChoices);
        if (!timerOn) setTimerOn(true);
    };

    const handleSubmit = async (event) => {
        await mutateResetScore({ exam_id: props.selected });
        event.preventDefault();
        const postData = {
            choice: JSON.stringify(userChoices.filter((choice) => choice !== undefined))
        };
        setTimeout(async () => {
            await mutate(postData);
        }, 300);
        endQuiz();
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    if (isLoadingQuestion) {
        return <div>Loading...</div>;
    }

    return (
        <div className='w-full h-full'>
            {isLoadingScoreQuiz ? (
                <div className="w-full h-full flex items-center justify-center">Loading...</div>
            ) : (
                <>
                    {!quizStarted && (
                        <>
                            {dataScoreQuiz && dataScoreQuiz.data.score > 0 ? (
                                <div className="w-full h-full flex items-center justify-center">
                                    <div className=''>
                                        <div className="block rounded-lg bg-white text-center border-2 p-8 ">
                                            <div className="text-start flex flex-col gap-2">
                                                <h5 className=" text-xl font-medium leading-tight text-neutral-800">
                                                    Selamat! Kamu Telah menyelesaikan test
                                                </h5>
                                                <p className="mb-2 text-lg text-neutral-600">
                                                    {dataQuestion?.data[0].exam.name}
                                                </p>
                                                <div className="flex gap-2 mb-2">
                                                    <div
                                                        className="bg-primary text-sm font-semibold text-blue-100 text-center p-1 px-2 rounded-md">
                                                        %
                                                    </div>
                                                    <ProgressBar
                                                        className='w-full '
                                                        completed={progress}
                                                        bgColor="#880C55"
                                                        borderRadius="6px"
                                                        height="25px"
                                                        labelAlignment="center"
                                                        labelColor="#ffffff"
                                                    />
                                                </div>
                                                <div className="flex gap-6 mb-2 px-4 items-center">
                                                    <div className="w-[2px] h-[70px] bg-gray-200" />
                                                    <div className="flex flex-col gap-4 items-center">
                                                        <h1 className='text-gray-500'>
                                                            Benar :
                                                        </h1>
                                                        <h1>
                                                            {dataScoreQuiz.data.score}
                                                        </h1>
                                                    </div>
                                                    <div className="w-[2px] h-[70px] bg-gray-200" />
                                                    <div className="flex flex-col gap-4 items-center">
                                                        <h1 className='text-gray-500'>
                                                            Soal :
                                                        </h1>
                                                        <h1>
                                                            {dataScoreQuiz.data.question}
                                                        </h1>
                                                    </div>
                                                    <div className="w-[2px] h-[70px] bg-gray-200" />
                                                    <div className="flex flex-col gap-4 items-center">
                                                        <h1 className='text-gray-500'>
                                                            Skor :
                                                        </h1>
                                                        <h1>
                                                            {(dataScoreQuiz.data.score / dataScoreQuiz.data.question * 100).toFixed(0)}
                                                        </h1>
                                                    </div>
                                                    <div className="w-[2px] h-[70px] bg-gray-200" />
                                                    <div className="flex flex-col gap-4 items-center">
                                                        <h1 className='text-gray-500'>
                                                            Waktu :
                                                        </h1>
                                                        <h1>
                                                            {formatTime(elapsedTime)}
                                                        </h1>
                                                    </div>
                                                    <div className="w-[2px] h-[70px] bg-gray-200" />

                                                </div>
                                            </div>
                                            <div className="flex justify-between pt-8">
                                                <button
                                                    type="button" onClick={startQuiz}
                                                    className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                                                    Ulangi Quiz
                                                </button>
                                                <button
                                                    type="button"
                                                    className="inline-block rounded border  px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                                                    Lanjut Belajar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ) : (
                                <div className="w-full h-full flex flex-col items-center justify-center">
                                    <ClipboardDocumentCheckIcon
                                        width={56}
                                        height={56}
                                        className="mb-2"
                                    />
                                    <p className="mb-2 text-xl font-medium leading-tight text-neutral-800">
                                        {dataQuestion && dataQuestion.data && dataQuestion.data.length > 0 ? dataQuestion.data[0].exam?.name : null}
                                    </p>
                                    <p className="mb-4 text-lg text-neutral-600">
                                        Passing Grade 80
                                    </p>
                                    <button
                                        type="button" onClick={startQuiz}
                                        className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                                        Mulai Quiz
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}

            {timerOn && dataQuestion?.data && (
                <>
                    <div className="p-4 mb-4 flex justify-between text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                        <span className="font-medium">{currentQuestionIndex + 1} / {totalQuestions}</span>
                        <span>
                            Waktu yang telah berlalu: {formatTime(timer)}
                        </span>
                    </div>

                    <form onSubmit={handleSubmit}>
                        {(dataQuestion?.data || []).map((question, index) => (
                            <div className='mb-6' key={question.id} style={{ display: index === currentQuestionIndex ? 'block' : 'none' }}>
                                <div className="flex flex-col gap-8">
                                    <Card>
                                        {question.content}
                                    </Card>
                                    <Card>
                                        {(question.choices || []).map((choice, choiceIndex) => (
                                            <div key={choice.id} className="flex  items-center ps-4 border border-gray-200 rounded focus:bg-blue-600/5">
                                                <input
                                                    id={`datachoice-${choice.id}`}
                                                    name={`datachoice-${choice.question_id}`}
                                                    type="radio"
                                                    value={choice.id}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-opacity-50 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    checked={userChoices[currentQuestionIndex]?.choice_id === String(choice.id)}
                                                    onChange={handleAnswerChange}
                                                />
                                                <label htmlFor={`datachoice-${choice.id}`} className="w-full py-4 ms-2 text-sm font-medium text-gray-900 hover:cursor-pointer">{choice.name}</label>
                                            </div>
                                        ))}

                                    </Card>
                                </div>
                            </div>
                        ))}
                        <div className="flex justify-between">
                            {currentQuestionIndex > 0 && (
                                <Button type="button" onClick={handlePrevQuestion}>Prev</Button>
                            )}
                            {currentQuestionIndex < totalQuestions - 1 && (
                                <Button type="button" onClick={handleNextQuestion}>Next</Button>
                            )}
                            {currentQuestionIndex === totalQuestions - 1 && (
                                <Button type="submit">Submit</Button>
                            )}
                        </div>
                    </form>
                </>
            )}

        </div>
    );
};

export default QuestionCourse;
