import { useMutation } from "@tanstack/react-query"

import { submitQuestion } from "../functions"
import { GeneralMutationParams } from "utils/types"
import { PublicSubmitQuestionResponse, QuestionRequest } from "../../types"

const useMutationSubmitQuiz = (
    options?: GeneralMutationParams<PublicSubmitQuestionResponse, QuestionRequest>
) => {
    return useMutation({
        mutationFn: (payload) => submitQuestion(payload),
        ...options,
    })
}

export default useMutationSubmitQuiz
